<template>
  <div class="parkingFee">
    <div style="background-color: rgba(255, 255, 255, 0.04);">
      <div class="title">出场车辆信息</div>
    </div>
    <div style="color:#fff" class="amount_received"></div>
    <div
      style="width: 100%; hight:512px; position: static"
      v-if="exitInfoList.length"
    >
      <div class="fixed-tab-bar">
        <div
          v-for="(item, index) in exitInfoList.map((item) => ({
            tabName: item.outCheckpoint?.name,
            id: item.outCheckpoint?.id,
          }))"
          :key="index"
          @click="selectTab(index)"
          :class="{ 'active-tab': selectedTab === index }"
          style="margin-right: 10px;"
        >
          {{ item.tabName }}
        </div>
      </div>
      <div class="mainInfo">
        <div class="entranceInfo">
          <div class="infoTitle">出场信息</div>
          <div class="infoItem">
            <span>出场车牌:</span>
            <!-- <span v-if="CurrParkingInfoList.length===0">{{''}}</span> -->
            <span class="margin-left-8" style="font-size: 20px;">{{
              exitInfoList[selectedTab]?.cfCarParkUseLog?.numberPlate || "未知"
            }}</span>
          </div>
          <!-- <div class="infoItem">
            <span>入场卡号:</span>
            <span>{{'无'}}</span>
          </div> -->
          <div class="infoItem">
            <span>入场时间:</span>
            <span v-if="!exitInfoList[selectedTab].cfCarParkUseLog?.inTime">入场时间未知</span>
            <span class="margin-left-8" v-else>{{
              formatTimestampToDateTime(
                exitInfoList[selectedTab].cfCarParkUseLog?.inTime
              )
            }}</span>
          </div>
          <div class="infoItem">
            <span>出场时间:</span>
            <span v-if="!exitInfoList[selectedTab].cfCarParkUseLog?.outTime">出场时间未知</span>
            <span class="margin-left-8" v-else>{{
              formatTimestampToDateTime(
                exitInfoList[selectedTab].cfCarParkUseLog?.outTime
              )
            }}</span>
          </div>
          <div class="infoItem">
            <span>车辆类型:</span>
            <span class="margin-left-8">{{
              exitInfoList[selectedTab]?.cfCarParkCarType?.name
            }}</span>
          </div>
        </div>
        <div class="feeBasedInfo">
          <div class="infoTitle">收费信息</div>
          <div class="infoItem">
            <span>停车时长:</span>
            <span v-if="!exitInfoList[selectedTab].cfCarParkUseLog?.outTime||!exitInfoList[selectedTab].cfCarParkUseLog?.inTime">出入场时间不完整</span>
            <span class="margin-left-8" v-else>{{
              formatDuration(
                exitInfoList[selectedTab].cfCarParkUseLog?.outTime,
                exitInfoList[selectedTab].cfCarParkUseLog?.inTime
              )
            }}</span>
          </div>
          <!-- <div class="infoItem">
            <span>收费规则:</span>
            <span>{{'小型汽车'}}</span>
          </div>
          <div class="infoItem">
            <span>减免规则:</span>
            <span>{{'无'}}</span>
          </div> -->
          <div class="infoItem">
            <span>应付金额:</span>
            <span class="margin-left-8"
              >{{ exitInfoList[selectedTab]?.cfOrder?.amountsPayable||0 }}元</span
            >
          </div>
          <div class="infoItem">
            <span>实付金额:</span>
            <span
              class="margin-left-8"
              style="color:#fc4408;font-size: 25px;"
              >{{ exitInfoList[selectedTab]?.cfOrder?.amountActuallyPaid||0 }}</span
            ><span style="margin-left: 8px;">元</span>
          </div>
        </div>
      </div>
      <el-dialog v-model="dialogTableVisible"> </el-dialog>
      <!-- <div
        style="width: 100%;display: flex;justify-content: center;"
        v-if="
          exitInfoList[selectedTab]?.cfOrder.amountsPayable > 0 &&
            exitInfoList[selectedTab]?.cfOrder.amountActuallyPaid == 0
        "
      >
        <el-button type="danger" size="small" @click="openDoor"
          >现金收费</el-button
        >
      </div> -->
    </div>
    <div v-else>
      <div
        v-if="exitInfoList.length === 0"
        style="color:#fff;text-align:center;margin-top:20px"
      >
        暂无停车数据
      </div>
    </div>

    <!-- 收费弹窗 -->
    <el-dialog
      v-model="dialogVisible"
      :show-close="false"
      custom-class="dialogFee"
      width="900px"
      :close-on-click-modal="false"
    >
      <template #title>
        <div class="my-header">
          <h3>收取费用</h3>
          <img @click="closeDialog" src="../../assets/close.svg" />
        </div>
      </template>

      <div class="dialog-content">
        <div style="display: flex;flex-direction: column;">
          <span style="margin-bottom: 10px;">入场照片</span>
          <img
            :src="exitInfoList[selectedTab].cfCarParkUseLog.inBigImage"
            style="width: 400px;"
          />
          <img
            :src="exitInfoList[selectedTab].cfCarParkUseLog.inSmallImage"
            style="height: 70px;"
          />
          <span style="margin-top: 10px;margin-bottom: 10px;">出场照片</span>
          <img
            :src="exitInfoList[selectedTab].cfCarParkUseLog.outBigImage"
            style="width: 400px;"
          />
          <img
            :src="exitInfoList[selectedTab].cfCarParkUseLog.outSmallImage"
            style="height: 70px;"
          />
        </div>
        <div
          style="background-color: #2e2d44;margin-left: 30px;border-radius: 5px;padding: 34px 20px;width: 373px;margin-top: 8px;"
        >
          <div style="margin-bottom: 20px;">
            <span>车牌号</span
            ><span style="margin-left: 43px;">{{
              exitInfoList[selectedTab]?.cfCarParkUseLog?.numberPlate || "未知"
            }}</span
            ><span
              style="background-color: #F56C6C;margin-left: 20px;font-size: 10px;padding: 5px 10px;border-radius: 99px;"
              >纠错</span
            >
          </div>
          <div style="margin-bottom: 20px;">
            <span>车辆类型</span
            ><span style="margin-left: 30px;">{{
              exitInfoList[selectedTab]?.cfCarParkCarType?.name
            }}</span>
          </div>
          <div style="margin-bottom: 20px;">
            <span>停车时长</span
            ><span style="margin-left: 30px;">{{
              formatDuration(
                exitInfoList[selectedTab].cfCarParkUseLog?.outTime,
                exitInfoList[selectedTab].cfCarParkUseLog?.inTime
              )
            }}</span>
          </div>
          <div
            style="height: 1px;background-color: #515151;margin:20px 0;"
          ></div>
          <div style="margin-bottom: 20px;">
            <span>入场关卡</span
            ><span style="margin-left: 30px;">{{
              exitInfoList[selectedTab].inCheckpoint.name
            }}</span>
          </div>
          <div style="margin-bottom: 20px;">
            <span>入场方式</span
            ><span style="margin-left: 30px;">{{
              getCarParkReleaseType(
                exitInfoList[selectedTab].cfCarParkUseLog.inReleaseType
              )
            }}</span>
          </div>
          <div style="margin-bottom: 20px;">
            <span>入场时间</span
            ><span style="margin-left: 30px;">{{
              formatTimestampToDateTime(
                exitInfoList[selectedTab].cfCarParkUseLog?.inTime
              )
            }}</span>
          </div>
          <div
            style="height: 1px;background-color: #515151;margin:20px 0;"
          ></div>
          <div style="margin-bottom: 20px;">
            <span>出场关卡</span
            ><span style="margin-left: 30px;">{{
              exitInfoList[selectedTab].outCheckpoint?.name
            }}</span>
          </div>
          <div style="margin-bottom: 20px;">
            <span>出场时间</span
            ><span style="margin-left: 30px;">{{
              formatTimestampToDateTime(
                exitInfoList[selectedTab].cfCarParkUseLog?.outTime
              )
            }}</span>
          </div>
          <div
            style="height: 1px;background-color: #515151;margin:20px 0;"
          ></div>
          <div style="margin-bottom: 10px;">
            <span>应收费用</span
            ><span style="margin-left: 30px;"
              >{{ exitInfoList[selectedTab].cfOrder?.amountsPayable }}元</span
            >
          </div>
          <div
            style="height: 1px;background-color: #515151;margin:20px 0;"
          ></div>
          <div style="margin-bottom: 20px;">
            <span>优惠券</span>
            <span style="margin-left: 43px;">
              <el-select
                v-model="value"
                class="m-2"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </span>
          </div>
          <div style="margin-bottom: 10px;">
            <span>优惠金额</span><span style="margin-left: 30px;">0</span>
          </div>
          <div
            style="height: 1px;background-color: #515151;margin:20px 0;"
          ></div>
          <div style="margin-bottom: 10px;">
            <span>实收金额</span
            ><span style="margin-left: 30px;font-size: 18px;color: #F56C6C;"
              >{{ exitInfoList[selectedTab].cfOrder?.amountsPayable }}元</span
            >
          </div>
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="openDoor" round>现金收费</el-button>
          <el-button type="danger" @click="unPaidRelease" round>
            未收费放行
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateLogAndOrder,
  carparkUseLogAdmin,
  updateParkingOrder,
  openOrCloseDoor,
} from "@/network/api";
import { mapState } from "vuex";
import { countPayAmountByDuty } from "../../network/api";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";

export default {
  emits: ["countPayAmountByDuty"],
  mounted() {
    this.tabs = this.exitInfoList.map((item) => ({
      tabName: item.outCheckpoint?.name,
      id: item.outCheckpoint?.id,
    }));
  },
  computed: {
    ...mapState(["exitInfoList", "exitInfoIndex"]),
  },
  //深度监听exitInfoList的变化
  watch: {
    exitInfoList: {
      handler: function(val) {
        this.CurrParkingInfoList = val;
        this.tabs = this.exitInfoList.map((item) => ({
          tabName: item.outCheckpoint?.name,
          id: item.outCheckpoint?.id,
        }));
        this.selectTab(this.exitInfoIndex);

        if (
          this.exitInfoList[this.selectedTab].cfOrder?.amountsPayable != 0 &&
          this.exitInfoList[this.selectedTab].cfOrder?.amountActuallyPaid == 0
        ) {
          this.dialogVisible = true;

          let conditions = {
            id: {
              operator: "=",
              value: this.exitInfoList[this.selectedTab].cfOrder.goodsId,
            },
            limit: {
              operator: "limit",
              page: 1,
              limit: 1,
            },
          };

          if (this.intervalId) {
            clearInterval(this.intervalId);
          }

          this.intervalId = setInterval(() => {
            this.queryOrderStatus(conditions);
          }, 5000);

          console.log("定时器添加之后", this.intervalId);

          setTimeout(() => {
            this.stopTimer();
          }, 10 * 60 * 1000);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      showMask: false, // 是否显示遮罩层
      dialogTableVisible: false,
      intervalId: null,
      // tabs 列表
      tabs: [
        {
          tabName: "出口A",
          id: 1,
        },
        {
          tabName: "出口B",
          id: 2,
        },
      ],
      // 当前选中的tab
      selectedTab: 0,
      CurrParkingInfoList: [], //当前停车场信息
      dialogVisible: false,
      carParkReleaseType: [
        { key: 0, name: "未知" },
        { key: 1, name: "自动抬杆" },
        { key: 2, name: "人工抬杆" },
        { key: 3, name: "手机放行" },
      ],
    };
  },
  methods: {
    // 选择tab
    selectTab(index) {
      // 如果当前tab已经被选中，则不执行任何操作
      if (this.selectedTab === index) {
        return;
      }
      this.selectedTab = index;
    },
    // 过滤tabs
    filteredTabs() {
      // 如果当前tab被选中，则显示
      if (this.selectedTab === 0) {
        return this.tabs;
      }
      // 否则，过滤掉当前tab
      return this.tabs.filter((item, index) => index !== this.selectedTab);
    },
    //转换时间戳
    formatTimestampToDateTime(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始，需要加 1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    // 计算时长
    formatDuration(startTime, endTime) {
      if (startTime > endTime) {
        [startTime, endTime] = [endTime, startTime]; // 交换时间戳，确保 startTime <= endTime
      }

      const timeDifference = endTime - startTime;
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      const remainingHours = hours % 24;
      const remainingMinutes = minutes % 60;
      const remainingSeconds = seconds % 60;

      // 格式化数字为两位数，不足两位数的部分补零
      const formatNumber = (number) => {
        return number < 10 ? `0${number}` : `${number}`;
      };

      // 构建格式化的时间间隔字符串
      const formattedDuration = `${formatNumber(days)}天${formatNumber(
        remainingHours
      )}小时${formatNumber(remainingMinutes)}分钟${formatNumber(
        remainingSeconds
      )}秒`;

      return formattedDuration;
    },

    // 现金收费
    async openDoor() {
      // 计算实付金额
      const amountsPayable =
        this.exitInfoList[this.selectedTab].cfCouponList.length > 0
          ? this.exitInfoList[this.selectedTab].cfOrder.amountsPayable -
            this.exitInfoList[this.selectedTab].cfCouponList[0].denomination
          : this.exitInfoList[this.selectedTab].cfOrder.amountsPayable;

      ElMessageBox.alert(
        `<strong style='font-size:25px'>${
          this.exitInfoList[this.selectedTab].cfCarParkUseLog?.numberPlate
        }出场收费<i style='color:red;'>${amountsPayable}</i>元</strong>`,
        "确认操作",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          showCancelButton: true,
          type: "warning",
        }
      )
        .then(async () => {
          const { amountsPayable } = this.exitInfoList[
            this.selectedTab
          ].cfOrder;
          const cfCouponList = this.exitInfoList[this.selectedTab].cfCouponList;

          // 现金收费接口
          const res = await updateLogAndOrder({
            carParkUseLogId: this.exitInfoList[this.selectedTab].cfOrder
              .goodsId,
            amountOfMoney: amountsPayable,
            couponId: cfCouponList.length > 0 ? cfCouponList[0].id : "",
            actionType: "cash_pay",
          });
          if (res.code === 10002) {
            this.$message({
              message: res.message,
              type: "success",
            });

            this.closeDialog();

            // 更新实付金额
            this.exitInfoList[
              this.selectedTab
            ].cfOrder.amountActuallyPaid = amountsPayable;

            // 更新顶部今日现金收费金额
            const { data, code } = await countPayAmountByDuty({
              paymentAgencyShortName: "cash_pay_cny",
            });
            if (code === 10002) {
              this.$store.commit("SETTOTALCASHCHARGE", data);
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => console.log(err));
    },
    getCarParkReleaseType(key) {
      const item = this.carParkReleaseType.find((item) => item.key == key);
      return item.name;
    },

    /**
     * 未收费放行
     */
    async unPaidRelease() {
      ElMessageBox.alert(
        `<strong style='font-size:25px'>${
          this.exitInfoList[this.selectedTab].cfCarParkUseLog?.numberPlate
        }未收费放行</strong>`,
        "确认操作",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          showCancelButton: true,
          type: "warning",
        }
      ).then(async () => {
        const res = await updateParkingOrder({
          id: this.exitInfoList[this.selectedTab].cfOrder.goodsId,
          type: "1",
          reportingTime: Date.now(),
        });
        if (res.code === 10002) {
          const res1 = await openOrCloseDoor({
            RedisPreFix: "release_",
            applicationReason: "未收费放行",
            checkPointId: this.exitInfoList[this.selectedTab].outCheckpoint.id,
            remarks: "未收费放行",
            type: "1",
            numberPlate: this.exitInfoList[this.selectedTab].cfCarParkUseLog
              .numberPlate,
          });
          if (res1.code === 10002) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.closeDialog();
          } else {
            this.$message.error(res1.message);
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },

    /**
     * 查询支付状态
     */
    queryOrderStatus(conditions) {
      carparkUseLogAdmin({
        conditions: JSON.stringify(conditions),
      }).then((res) => {
        console.log(res.data[0].cfOrder);
        if (res.code === 10002) {
          if (res.data[0].cfOrder.status == 1) {
            ElMessage({
              message: `${res.data[0].numberPlate}用户支付成功`,
              type: "success",
              duration: 5000,
            });

            // 更新实付金额
            this.exitInfoList[this.selectedTab].cfOrder.amountActuallyPaid =
              res.data[0].cfOrder.amountActuallyPaid;

            this.closeDialog();
          }
        } else {
          ElMessage({
            message: "订单状态异常：" + res.message,
            type: "error",
            duration: 5000,
          });
          this.closeDialog();
        }
      });
    },

    /**
     * 关闭收费弹窗和定时器
     */
    closeDialog() {
      this.dialogVisible = false;
      clearInterval(this.intervalId);

      console.log("定时器结束之后", this.intervalId);
    },

    /**
     * 超时未支付
     */
    stopTimer() {
      if (this.intervalId) {
        ElMessage({
          message: "用户超时未支付",
          type: "warning",
          duration: 5000,
        });
        this.closeDialog();
      }
    },
  },
};
</script>

<style>
.dialogFee {
  background-color: #24233c;
  border-radius: 10px;
}

.dialogFee .el-dialog__header {
  background-color: #2e2d44;
  border-radius: 10px;
}

.dialogFee .el-dialog__body {
  padding-bottom: 10px;
}
</style>

<style scoped>
.dialog-footer {
  margin-right: 20px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #fff;
  position: relative;
}

.my-header img {
  width: 20px;
  position: absolute;
  right: 0;
}

.dialog-content {
  color: white;
  display: flex;
  margin-left: 30px;
}

.parkingFee {
  width: 15%;
  height: 512px;
  margin-left: 15px;
  background-color: #25243c;
  border-radius: 10px;
}
.parkingFee .title {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #fff;
  padding-left: 15px;
}
.amount_received {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  color: #fff;
}
.fixed-tab-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  overflow: hidden;
  line-height: 50px;
  /* background-color: #555265; */
  color: #fff;
  font-size: 16px;
}
.active-tab {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.mainInfo {
  width: 100%;
  padding: 0px 10px;
  padding-top: 5px;
  color: #fff;
}
.mainInfo .entranceInfo {
  /* background-color: #777584; */
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 5px;
}
.mainInfo .carInfo {
  /* background-color: #777584; */
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 5px;
}
.mainInfo .feeBasedInfo {
  /* background-color: #777584; */
  padding: 8px;
  border-radius: 5px;
}
.mainInfo .infoTitle {
  font-weight: 700;
}
.infoItem {
  padding: 8px 0;
  margin-left: 20px;
}

.margin-left-8 {
  margin-left: 8px;
}
</style>
