<template>
  <el-dialog
    v-model="dialogVisible"
    title="订单列表"
    :before-close="handleClose"
    fullscreen
    :style="{ 'font-size':'24px' }"
  >
    <el-form ref="form" :inline="true" :model="listQuery" label-width="100px">
      <el-row type="flex" style="margin: 20px 0 0 88px; flex-wrap: wrap;">
        <el-col>
          <el-row type="flex" style="width:1000px;height:100%;">
            <div style="display: inline-block;width:1920px">
              <el-form-item>
                <el-input
                  v-model="listQuery.idEQ.value"
                  clearable
                  placeholder="id精确查找"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="listQuery.goodsNameLK.value"
                  clearable
                  placeholder="商品名搜索"
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="type"
                  class="filter-item"
                  clearable
                  placeholder="支付机构等于"
                >
                  <el-option label="停车" value="parking" />
                  <el-option label="充电" value="charging" />
                </el-select>
              </el-form-item>
              <!--            <el-form-item v-if="fold">-->
              <!--              <el-input-->
              <!--                v-model="uidEQ"-->
              <!--                clearable-->
              <!--                placeholder="用户id精准搜索"-->
              <!--              />-->
              <!--            </el-form-item>-->
              <el-form-item v-if="fold">
                <el-select
                  v-model="listQuery.uidEQ.value"
                  clearable
                  filterable
                  placeholder="用户名搜索"
                  remote
                  :remote-method="userRemoteMethod"
                >
                  <el-option
                    v-for="item in userOptions"
                    :key="item.id"
                    :label="item.userName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <!--            <el-form-item v-if="fold">-->
              <!--              <el-input-->
              <!--                v-model="effect_object"-->
              <!--                clearable-->
              <!--                placeholder="请输入"-->
              <!--              />-->
              <!--            </el-form-item>-->
              <el-form-item v-if="fold">
                <el-input
                  v-model="listQuery.goodsIdEQ.value"
                  clearable
                  placeholder="商品id精准搜索"
                />
              </el-form-item>
              <el-form-item v-if="fold">
                <el-input
                  v-model="listQuery.thirdPartyOrderIdEQ.value"
                  clearable
                  placeholder="第三方单号精准搜索"
                />
              </el-form-item>
              <el-form-item v-if="fold">
                <el-input
                  v-model="listQuery.shopIdEQ.value"
                  clearable
                  placeholder="店铺id精准搜索"
                />
              </el-form-item>
              <el-form-item v-if="fold">
                <el-input
                  v-model="listQuery.couponIdEQ.value"
                  clearable
                  placeholder="优惠券id精准搜索"
                />
              </el-form-item>
              <el-form-item v-if="fold">
                <el-select
                  v-model="listQuery.paymentAgencyShortNameEQ.value"
                  class="filter-item"
                  clearable
                  placeholder="支付机构等于"
                >
                  <el-option
                    v-for="item in listQuery.paymentAgencyOptions"
                    :key="item.shortName"
                    :label="item.name"
                    :value="item.shortName"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-if="fold">
                <el-select
                  v-model="listQuery.statusEQ.value"
                  clearable
                  placeholder="请选择状态"
                >
                  <el-option
                    v-for="item in listQuery.statusOptions"
                    :key="item.key"
                    :label="item.name"
                    :value="item.key"
                  />
                </el-select>
              </el-form-item>

              <el-form-item v-if="fold">
                <el-select
                  v-model="listQuery.goodsTypeEQ.value"
                  clearable
                  placeholder="请选择商品类型"
                >
                  <el-option
                    v-for="item in goodsTypeOptions"
                    :key="item.key"
                    :label="item.name"
                    :value="item.key"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-if="fold">
                <el-date-picker
                  v-model="listQuery.payTimeArr"
                  end-placeholder="支付时间结束"
                  range-separator="至"
                  start-placeholder="支付时间开始"
                  type="datetimerange"
                  value-format="x"
                  @change="payTimeChangeFn"
                />
              </el-form-item>
              <el-form-item>
                <el-button type="text" @click="handleFold">
                  <span v-if="fold">合并</span>
                  <span v-else>展开</span>
                  <vab-icon
                    class="vab-dropdown"
                    :class="{ 'vab-dropdown-active': fold }"
                    icon="arrow-up-s-line"
                  />
                </el-button>
              </el-form-item>
              <!-- <el-form-item>
                <el-button :icon="Search" native-type="submit" type="primary" @click="fetchData($event)">
                  查询
                </el-button>
                <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
                  删除
                </el-button>
              </el-form-item> -->
            </div>
          </el-row>
          <el-row type="flex" style="width: 700px"> </el-row>
        </el-col>
      </el-row>
    </el-form>
    <el-form-item>
      <el-button
        :icon="Search"
        native-type="submit"
        type="primary"
        @click="fetchData($event)"
      >
        查询
      </el-button>
      <!--            <el-button :icon="Plus" type="primary" @click="handleAdd">-->
      <!--              添加-->
      <!--            </el-button>-->
      <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
        删除
      </el-button>
    </el-form-item>
    <el-table
      ref="tableSortRef"
      v-loading="listLoading"
      :border="border"
      :data="list"
      :height="height"
      :size="lineHeight"
      :stripe="stripe"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column
        align="center"
        label="序号"
        show-overflow-tooltip
        width="55"
      >
        <template #default="{ $index }">
          <div @click="onSerialNumber($index, $event)">{{ $index + 1 }}</div>
        </template>
      </el-table-column>

      <!--  TODO element-plus未知原因不支持拖拽后宽度重新计算，暂时放弃 -->
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :sortable="item.sortable"
      >
        <template #default="{ row }">
          <span v-if="item.label =='取消时间'">
            {{item.cancelTime?  dayjs(item.cancelTime).format("YYYY-MM-DD HH:mm:ss"):'-'}}
          </span>
          <span v-else-if="item.label =='退款时间'">
            {{item.cancelTime?dayjs(item.cancelTime).format("YYYY-MM-DD HH:mm:ss"):'-'}}
          </span>
          <span v-else>
            {{ row[item.prop] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        show-overflow-tooltip
        width="100"
      >
        <template #default="{ row }">
          <!--          <el-button size="small" @click="handleEdit(row)">编辑</el-button>-->
          <el-button size="small" type="danger" @click="handleDelete(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="listQuery.limit.page"
      :page-size="listQuery.limit.limit"
      :page-sizes="[10, 20, 50, 100]"
      :layout="layout"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="total"
      style="margin-top: 10px"
    />
  </el-dialog>
</template>
<script>
import {
  // carparkUseLogAdmin,
  getAllCarTypeList,
  getDutyOrderList,
  selecPaymentAgencytListByQuery,
} from "@/network/api";
import { handleFilterAdminParams, $timeFormat, timeFn } from "@/utils/index";
import { listQuery } from "./queruData";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  data() {
    return {
      list: [],
      dialogVisible: false,
      carTypeOptions: [],
      listLoading: false,
      carStatusOption: ["禁用", "正常", "已过期"],
      listQuery: listQuery,
      layout: "total, sizes, prev, pager, next, jumper",
      carParkReleaseType: [
        { key: 0, name: "未知" },
        { key: 1, name: "自动抬杆" },
        { key: 2, name: "人工抬杆" },
        { key: 3, name: "手机放行" },
      ],
      paymentAgencyShortNameOptions: [
        { shortName: "cash_pay_cny", name: "现金支付" },
        { shortName: "pay_online", name: "电子支付" },
        { shortName: "abnormal_termination_of_order", name: "异常放行" },
      ],
      total: 0,
      locale: zhCn,
      inTimeData: [],
      outTimeData: [],
      // carParkOptions: [],

      fold: false,
      type: "parking",
      paymentAgencyOptions: [],
      userOptions: [],
      statusOptions: [
        { key: 0, name: "待支付" },
        { key: 1, name: "已支付" },
        { key: 2, name: "已发货" },
        { key: 3, name: "已取消" },
        { key: 4, name: "退款中" },
        { key: 5, name: "已退款" },
        { key: 6, name: "退货中" },
        { key: 7, name: "已退货" },
        { key: 8, name: "交易成功" },
        { key: 9, name: "售后中" },
        { key: 10, name: "售后已结束" },
      ],
      goodsTypeOptions: [
        { key: 1, name: "停车" },
        { key: 2, name: "充值提现" },
        { key: 3, name: "商城" },
        { key: 4, name: "转账收款" },
        { key: 5, name: "停车套餐" },
        { key: 6, name: "车位预订" },
        { key: 7, name: "优惠券充值" },
      ],
      tableSortRef: null,
      stripe: false,
      // listLoading: false,
      border: true,
      // list: [],
      lineHeight: "large",
      selectRows: "",
      // total: 0,
      // layout: 'total, sizes, prev, pager, next, jumper',
      checkList: [
        "商品名",
        "应付金额",
        "代付金额",
        "实付金额",
        "状态",
        "支付时间",
      ],
      columns: [
        {
          label: "商品名",
          prop: "goodsName",
        },
        {
          label: "应付金额",
          prop: "amountsPayable",
          sortable: true,
        },
        {
          label: "代付金额",
          prop: "collectionAmount",
          sortable: true,
        },
        {
          label: "实付金额",
          prop: "amountActuallyPaid",
          sortable: true,
        },
        {
          label: "商品类型",
          prop: "goodsType",
        },
        {
          label: "状态",
          prop: "newStatus",
        },
        {
          label: "支付机构",
          prop: "newPaymentAgencyShortName",
        },
        {
          label: "操作人id",
          prop: "handleUid",
        },
        {
          label: "店铺id",
          prop: "shopId",
        },
        {
          label: "支付时间",
          prop: "newPayTime",
        },
        {
          label: "退款时间",
          prop: "refundTime",
        },
        {
          label: "退款金额",
          prop: "refundAmount",
        },
        {
          label: "取消时间",
          prop: "cancelTime",
        },
      ],
    };
  },
  computed: {
    ...mapState(["carParkList"]),
  },
  created() {
    // this.carParkOptions = this.carParkList;
  },
  methods: {
    handleHeight() {
      // if (isFullscreen.value) state.height = $baseTableHeight(1) + 200
      // else state.height = $baseTableHeight(1)
    },
    payTimeChangeFn(val) {
      if (val) {
        this.listQuery.payTimeGT.value = val[0];
        this.listQuery.payTimeLT.value = val[1];
      } else {
        this.listQuery.payTimeArr = [];
        this.listQuery.payTimeGT.value = "";
        this.listQuery.payTimeLT.value = "";
      }
    },
    handleFold() {
      this.fold = !this.fold;
      this.handleHeight();
    },
    handleClose(done) {
      done();
    },
    getCarParkReleaseType(key) {
      return this.carParkReleaseType.filter((i) => i.key == key)[0]?.name;
    },
    timeFormat(time, type) {
      return $timeFormat(time, type);
    },
    showDialog() {
      this.dialogVisible = true;
      this.getAllCarTypeList();
      this.fetchData();
      this.getselecPaymentAgencytListByQuery();
    },
    async getAllCarTypeList() {
      const { data, code } = await getAllCarTypeList();
      if (code == 10002) {
        this.carTypeOptions = data;
      } else {
        this.carTypeOptions = [];
      }
    },
    handleQuery() {
      this.listQuery.limit.page = 1;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.listQuery.limit.limit = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.listQuery.limit.page = val;
      this.fetchData();
    },

    getTimeFn(inTime, outTime) {
      return timeFn(inTime, outTime);
    },
    async getselecPaymentAgencytListByQuery() {
      const { data, code } = await selecPaymentAgencytListByQuery({});
      console.log(data, code, "data, code");
      if (code == 10002) {
        this.listQuery.paymentAgencyOptions = data;
        // console.log(1111111111111111111, this.listQuery.paymentAgencyOptions);
      } else {
        this.listQuery.paymentAgencyOptions = [];
      }
    },
    // 解决时间搜索 清除后为null的问题
    timeRepairNull(obj) {
      // console.log(obj, 1111111111111111111);
      for (const i in obj) {
        if (i != "limit") {
          if (
            obj[i] !== null &&
            typeof obj[i] === "object" &&
            obj[i].value == null
          ) {
            obj[i].value = "";
          }
        }
      }
      // console.log(obj, 2222222222222222222);
      return obj;
    },
    getCarTypeName(carType) {
      var carTypeOptions = this.carTypeOptions;
      if (!carType) return "临时车";
      for (let index = 0; index < carTypeOptions.length; index++) {
        if (carTypeOptions[index]?.flagKey == carType) {
          return carTypeOptions[index]?.name;
        }
      }
    },
    // async fetchData() {
    //   this.listLoading = true;

    //   if (this.inTimeData?.length > 0) {
    //     this.listQuery.inTimeGT.value = dayjs(this.inTimeData[0]).valueOf();
    //     this.listQuery.inTimeLT.value = dayjs(this.inTimeData[1]).valueOf();
    //   } else {
    //     // this.listQuery.inTimeGT.value = null;
    //     // this.listQuery.inTimeLT.value = null;
    //   }

    //   if (this.outTimeData?.length > 0) {
    //     this.listQuery.outTimeGT.value = dayjs(this.outTimeData[0]).valueOf();
    //     this.listQuery.outTimeLT.value = dayjs(this.outTimeData[1]).valueOf();
    //   } else {
    //     // this.listQuery.outTimeGT.value = null;
    //     // this.listQuery.outTimeLT.value = null;
    //   }

    //   const { data, total, code, message } = await getDutyOrderList({
    //     conditions: handleFilterAdminParams(this.timeRepairNull(this.listQuery)),
    //   });
    //   this.listLoading = false;

    //   if (code != 10002) {
    //     this.$message.error(message);
    //     return;
    //   }
    //   console.log(data, total, "data, total");
    //   this.list = data;
    //   this.total = total;
    // },
    async fetchData() {
      //阻止表单提交默认刷新页面的行为
      // e.preventDefault();
      // this.listLoading = true;
      // if (this.listQuery.payTimeArr?.length > 0) {
      //   this.listQuery.payTimeGT.value = this.payTimeArr[0];
      //   this.listQuery.payTimeLT.value = this.payTimeArr[1];
      // } else {
      //   this.listQuery.payTimeGT.value = '';
      //   this.listQuery.payTimeLT.value = '';
      // }
      this.listQuery.payTimeGT.value = "";
      this.listQuery.payTimeLT.value = "";
      this.listLoading = true;

      try {
        const { data, total, code } = await getDutyOrderList({
          conditions: handleFilterAdminParams(this.listQuery),
          scenes: this.type,
        });
        if (code === 10002) {
          data.forEach((i) => {
            i.newStatus = i.status === 0 ? "待支付" : "已支付";
            i.newPayTime = i.payTime
              ? dayjs(i.payTime).format("YYYY-MM-DD HH:mm:ss")
              : "-";
            i.newPaymentAgencyShortName = this.listQuery.paymentAgencyOptions.find(
              (j) => i.paymentAgencyShortName == j.shortName
            )?.name;
          });

          this.list = data;

          if (total) this.total = total;
        } else {
          this.list = [];
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.listLoading = false;
      // this.listLoading = false;
    },
  },
};
</script>

<style>
.el-dialog__headerbtn .el-dialog__close {
  font-size: 45px !important;
}
</style>
