export const listQuery = {
  idEQ: {
    operator: "=",
    field: "id",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  carParkIdEQ: {
    operator: "=",
    field: "car_park_id",
    value: "",
    alias: "cpp",
    dataType: "string",
  },
  numberPlateEQ: {
    operator: "=",
    field: "number_plate",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  numberPlateLK: {
    operator: "like",
    field: "number_plate",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  visitUnitLK: {
    operator: "like",
    field: "visit_unit",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  inReleaseTypeEQ: {
    operator: "=",
    field: "in_release_type",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  outReleaseTypeEQ: {
    operator: "=",
    field: "out_release_type",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  inCheckPointIdEQ: {
    operator: "=",
    field: "in_check_point_id",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  outCheckPointIdEQ: {
    operator: "=",
    field: "out_check_point_id",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  createPositionEQ: {
    operator: "=",
    field: "create_position",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  carTypeEQ: {
    operator: "=",
    field: "car_type",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  paymentAgencyShortNameEQ: {
    operator: "=",
    field: "payment_agency_short_name",
    value: "",
    alias: "ord",
    dataType: "string",
  },
  feeEQ: {
    operator: "=",
    field: "is_fee",
    value: "",
    alias: "",
    dataType: "string",
  },
  carOwnerNameLK: {
    operator: "=",
    field: "car_owner_name",
    value: "",
    alias: "cpp",
    dataType: "string",
  },
  inTimeLT: {
    operator: "<=",
    field: "in_time",
    value: "",
    alias: "cpul",
    dataType: "date",
  },
  inTimeGT: {
    operator: ">=",
    field: "in_time",
    value: "",
    alias: "cpul",
    dataType: "date",
  },
  outTimeLT: {
    operator: "<=",
    field: "out_time",
    value: "",
    alias: "cpul",
    dataType: "date",
  },
  outTimeGT: {
    operator: ">=",
    field: "out_time",
    value: "",
    alias: "cpul",
    dataType: "date",
  },
  subscribeTimeLT: {
    operator: "<=",
    field: "subscribe_time",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  subscribeTimeGT: {
    operator: ">=",
    field: "subscribe_time",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  cancelSubscribeTimeLT: {
    operator: "<=",
    field: "cancel_subscribe_time",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  cancelSubscribeTimeGT: {
    operator: ">=",
    field: "cancel_subscribe_time",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  payTimeLT: {
    operator: "<=",
    field: "pay_time",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  payTimeGT: {
    operator: ">=",
    field: "pay_time",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  createTimeLT: {
    operator: "<=",
    field: "create_time",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  createTimeGT: {
    operator: ">=",
    field: "create_time",
    value: "",
    alias: "cpul",
    dataType: "number",
  },
  outTimeORDER: {
    operator: "order",
    field: "out_time",
    value: "desc",
    alias: "cpul",
    dataType: "string",
  },
  inTimeORDER: {
    operator: "order",
    field: "in_time",
    value: "desc",
    alias: "cpul",
    dataType: "string",
  },
  subscribeTimeORDER: {
    operator: "order",
    field: "subscribe_time",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  cancelSubscribeTimeORDER: {
    operator: "order",
    field: "cancel_subscribe_time",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  payTimeORDER: {
    operator: "order",
    field: "pay_time",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  createTimeORDER: {
    operator: "order",
    field: "create_time",
    value: "",
    alias: "cpul",
    dataType: "string",
  },
  limit: { page: 1, limit: 10 },
  // group: {
  //     operator: "group",
  //     field: "id",
  //     value: "",
  //     alias: "cpul",
  //     dataType: "string",
  // },
};
