const CompanyArr = {
  wx: {
    requestUrl: "https://api.jxwenxin.com",
    // requestUrl: "http://192.168.0.38",
    title: "佰逸芯停车系统岗亭端",
    name: "jxwenxin",
  },

  test: {
    requestUrl: "https://test.park.cfeng.wang",
    title: "测试环境",
    name: "测试",
  },
};

module.exports = CompanyArr.wx;

