import ReconnectingWebSocket from "reconnecting-websocket";
// import { WebSocketUrl } from "../config/index";
import store from "../store/index";
import { selectPackageListByQuery, queryOrderByUseId } from "../network/api";
import { ElNotification } from "element-plus";

var websocket = null;

export const InitWebSocket = () => {
  // 发送心跳
  const reateLinkAndSendheartBeat = () => {
    let message = initNullMessagePojo();
    message.type = "create_link";
    websocket.send(JSON.stringify(message));
    setInterval(() => {
      let _message = initNullMessagePojo();
      _message.type = "heartbeat_check";
      websocket.send(JSON.stringify(_message));
    }, 10000);
  };

  const initNullMessagePojo = () => {
    let cfUserMessage = {
      id: "",
      fromUid: "",
      toUid: "",
      groupId: "",
      status: 0,
      contents: "",
      type: 0,
      client: "",
      ip: "",
      createTime: 0,
      receiveTime: 0,
    };

    let message = {
      type: "",
      cfUserMessage: cfUserMessage,
      ext: "",
      token: localStorage.getItem("token"),
    };
    return message;
  };

  // 向Store推送消息
  const pushStoreData = (data, type) => {
    const carParkId = type == "out" ? data.cfCarPark.id : data.carParkId;
    // console.log(data, type,'pushStoreData')
    // 保存到最近出入场记录（最近出入场记录中使用）
    if (type === "out") store.commit("SETOUT", data);
    if (type === "in") store.commit("SETIN", data);

    // 判断是用来选择哪个停车场 就推哪个停车场的信息
    if (carParkId === store.getters.carParkId) {
      // 保存出入场图片(显示出入场小图中使用)
      if (type === "out") {
        store.commit("SETOUTIMAGE", {
          outImage: data.cfCarParkUseLog?.outBigImage,
          outSmallImage: data.cfCarParkUseLog?.outSmallImage,
        });
      } else {
        store.commit("SETINIMAGE", {
          inImage: data.inBigImage,
          inSmallImage: data.inSmallImage,
        });
      }

      // 加入出入场列表（显示出入场车辆信息中使用）
      if (type === "out") {
        store.commit("SETEXITINFOLIST", data);
      }
      if (type === "in") {
        store.commit("SETININFOLIST", data);
      }
    }
  };

  // 获取账单信息
  const getQueryOrderByUseId = async (carParkUseLog) => {
    console.log(carParkUseLog,11)
    const { data, code } = await queryOrderByUseId(carParkUseLog.id);
    if (code != 10002) return;

    // 判断是否设置只显示收费车辆信息
    if (store.state.globalConfig.promptChargeOutCar) {
      if (data.cfOrder.amountsPayable > 0) {
        pushStoreData(data, "out"); 
      }
    } else {
      data.carParkUseLog = carParkUseLog
      pushStoreData(data, "out");
      // pushStoreData(carParkUseLog,'out')
    }

    // if (data.cfCouponList != "" && data.cfCouponList.length > 0) {
    //   data.cfOrder.couponDenomination = data["cfCouponList"][0]["denomination"];
    // } else {
    //   data.cfOrder.couponDenomination = 0;
    // }
    // if (
    //   data.cfOrder.amountsPayable - data.cfOrder.couponDenomination > 0 &&
    //   data.cfOrder.status == 0 &&
    //   !data.cfOrder.collectionShopId &&
    //   data.cfOrder.payTime === 0 &&
    //   carParkUseLog.payTime === 0
    // ) {
    // console.log("被收费的车俩", data);
    // store.commit("SETCHARGECAR", data);
    // }
  };

  const updateInterface = async (message) => {
    // console.log("收到服务端的消息", JSON.parse(message.cfUserMessage.contents));

    if (message == null) return;

    //修改消息状态为已读
    let consumptionData = initNullMessagePojo();
    consumptionData.type = "change_status";
    consumptionData.cfUserMessage.id = message.cfUserMessage.id;
    websocket.send(JSON.stringify(consumptionData));

    // 将数据放到页面中进行展示
    let carParkUseLog = JSON.parse(message.cfUserMessage.contents);
    if (message.cfUserMessage.type == 1) {
      // 设置出入场图片(显示出入场图片使用)
      store.commit("SETBIGIMAGE", carParkUseLog);

      // 更新界面:包括 图片 消息 更新到页面
      // 只提示选中停车场的停车数据
      if (carParkUseLog.carParkId == store.getters.carParkId) {
        if (store.state.globalConfig.promptEntryExitCar) {
          if (carParkUseLog.outTime == 0) {
            ElNotification({
              title: "提示",
              message: `${carParkUseLog.cfCarParkCheckpointIn.name} ${carParkUseLog.releaseNotice}`,
              position: "bottom-left",
              type: "success",
            });
          } else {
            ElNotification({
              title: "提示",
              message: `${carParkUseLog.cfCarParkCheckpointOut.name} ${carParkUseLog.releaseNotice}`,
              position: "bottom-left",
              type: "success",
            });
          }
        }
      }

      // 入场车辆处理
      if (carParkUseLog.inTime > 0 && carParkUseLog.outTime == 0) {
        // this.nowInBigImage = carParkUseLog.inBigImage;
        // 查询车辆套餐信息
        const { data } = await selectPackageListByQuery({
          carParkId: carParkUseLog.carParkId,
          numberPlate: carParkUseLog?.numberPlate,
        });
        carParkUseLog.carOwnerName=data[0]?.carOwnerName||''
        carParkUseLog.cfCarParkCarType =
          data == null
            ? {
                name: "临时车",
                flagKey: "temporary_car",
              }
            : data[0].cfCarParkCarType;
        pushStoreData(carParkUseLog, "in");
      }

      // 出场车辆处理
      if (carParkUseLog.outTime > 0) {
        const { data } = await selectPackageListByQuery({
          carParkId: carParkUseLog.carParkId,
          numberPlate: carParkUseLog?.numberPlate,
        });
        carParkUseLog.cfCarParkCarType =
          data == null
            ? {
                name: "临时车",
                flagKey: "temporary_car",
              }
            : data[0].cfCarParkCarType;
        getQueryOrderByUseId(carParkUseLog);
      }
    } else if (message.cfUserMessage.type == 6) {
      store.commit("SetLicenseplateCorrection", {
        show: true,
        data: message,
      });
    } else {
      // 用户已支付 取消弹窗
      console.log("已支付的车辆信息", carParkUseLog);
      store.commit("PAIDCAR", carParkUseLog);
      // this.$refs.ChargeBox.deleteData(carParkUseLog);
    }
  };

  if (window.WebSocket) {
    const userInfo = localStorage.getItem("userInfo");

    websocket = new ReconnectingWebSocket(
      `wss://api.jxwenxin.com/wss/WebSocketServer/${JSON.parse(userInfo).id}`,
      // `ws://192.168.0.38:8199/WebSocketServer/${JSON.parse(userInfo).id}`,
      null,
      {
        debug: false,
        // 重连间隔时间
        reconnectInterval: 5000,
      }
    );

    websocket.onopen = () => {
      // console.log("WebSocket连接开启", e);
      // 启动心跳
      reateLinkAndSendheartBeat();
    };

    // 接受webSocket消息
    websocket.onmessage = (e) => {
      if (e.data == "连接成功") {
        console.log("连接服务端成功！");
        return;
      }

      var message = JSON.parse(e.data);
      setTimeout(function() {
        // 数据处理
        updateInterface(message);
      }, 1000);
    };

    websocket.onclose = (e) => {
      console.log(
        "websocket 断开: " + e.code + " " + e.reason + " " + e.wasClean
      );
    };
  } else {
    alert("您的浏览器不支持webSocket！");
  }
};
