<template>
  <div className="layer1 flex-col">
    <div className="box1 flex-col">
      <div className="section1 flex-row">
        <div className="flex-row">
          <img
            v-if="company.name != 'dewei' && company.name != 'sixiang'"
            className="icon1"
            src="../../assets/cf.png"
          />
          <img
            v-if="company.name == 'sixiang'"
            className="icon1"
            style="margin-top:2px;width: 28px;height: 34px;"
            src="../../assets/sixianglogo.png"
            alt=""
          />
          <span className="word1">{{ company.title }}</span>
          <span className="info1">{{ nowTime }}</span>
        </div>
        <div className="flex-row">
          <span className="word2" style="margin-right: 18px;">
            今日现金收费：{{ totalCashCharge }}
          </span>
          <span className="word2" style="margin-right: 18px">
            <span>{{
            user.userName
          }}</span>
          <span style="margin:0 10px;cursor:pointer;border-radius: 10px;font-size: 12px;padding: 4px;background:#fc4408" @click="logOut">退出登录</span>
          </span>
          <el-button
            type="danger"
            icon="el-icon-edit"
            circle
            @click="drawer = true"
          ></el-button>
          <el-button className="main1 flex-col" @click="onParkingRecord">
            <span className="word3">停车记录</span>
          </el-button>
          <el-button className="main1 flex-col" @click="showVideo">
            <span className="word3">实时视频</span>
          </el-button>

          <!-- <el-button className="main1 flex-col" @click="showStatement">
            <span className="word3">财务报表</span>
          </el-button> -->
          <el-button className="main1 flex-col" @click="showOrderList">
            <span className="word3">订单列表</span>
          </el-button>

          <div className="main2 flex-col" @click="onLogin">
            <span className="info2">点我交班</span>
          </div>
          
        </div>
      </div>
      <div className="section2 flex-col" />
    </div>

    <HandOver ref="handover" />
    <ParkingRecord ref="ParkingRecord" />
    <OrderList ref="OrderList" />
    <!-- <Statement ref="Statement" /> -->
    <flvVideow ref="flvVideow"></flvVideow>
  </div>

  <el-drawer v-model="drawer" title="全局配置文件">
    <div style="display: flex">
      <div>进出场车辆提示：</div>
      <el-switch
        v-model="globalConfig.promptEntryExitCar"
        active-text="开启"
        inactive-text="关闭"
      />
    </div>
    <div style="display: flex;margin-top: 10px">
      <div>已支付车辆提示：</div>
      <el-switch
        v-model="globalConfig.promptPayCar"
        active-text="开启"
        inactive-text="关闭"
      />
    </div>
    <div style="display: flex;margin-top: 10px">
      <div>出场记录只显示收费车辆：</div>
      <el-switch
        v-model="globalConfig.promptChargeOutCar"
        active-text="开启"
        inactive-text="关闭"
      />
    </div>
    <div style="display: flex;margin-top: 10px">
      <div>进出场图片显示：</div>
      <el-switch
        v-model="globalConfig.inOutImageType"
        active-text="小图"
        inactive-text="大图"
      />
    </div>
  </el-drawer>
</template>

<script>
import HandOver from "./HandOver.vue";
import ParkingRecord from "../ParkingRecord";
import OrderList from "../OrderList";
import flvVideow from "../flvViodeow";
import { mapState } from "vuex";
import dayjs from "dayjs";
import store from "../../store";
import CompanyArr from "../../config";
// import {useRouter} from 'vue-router'
// const router = useRouter()
export default {
  components: { HandOver, ParkingRecord, flvVideow, OrderList },
  computed: {
    ...mapState(["globalConfig", "totalCashCharge"]),
  },
  data() {
    return {
      nowTime: "",
      user: JSON.parse(localStorage.getItem("userInfo")),
      drawer: false,
      company: CompanyArr,
    };
  },
  created() {
    this.setTime();
  },
  watch: {
    globalConfig: {
      deep: true,
      handler(value) {
        store.commit("SETCONFIG", value);
      },
    },
  },
  methods: {
    onLogin() {
      this.$refs.handover.showDialog();
    },
    logOut(){
      localStorage.clear()
      location.reload()
    },
    setTime() {
      setInterval(() => {
        this.nowTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (this.nowTime.slice(-8) == "23:59:59") {
          store.commit("SETTOTALCASHCHARGE", 0);
        }
      }, 1000);
    },
    onParkingRecord() {
      this.$refs.ParkingRecord.showDialog();
    },
    showVideo() {
      this.$refs.flvVideow.showDialog();
    },
    showStatement() {
      this.$refs.Statement.showDialog();
    },
    showOrderList() {
      this.$refs.OrderList.showDialog();
    },
  },
};
</script>
