export const listQuery = {
  idEQ: {
    operator: '=',
    field: 'id',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  uidEQ: {
    operator: '=',
    field: 'uid',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  goodsNameLK: {
    operator: 'like',
    field: 'goods_name',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  goodsIdEQ: {
    operator: '=',
    field: 'goods_id',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  goodsTypeEQ: {
    operator: '=',
    field: 'goods_type',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  amountsPayableGT: {
    operator: '>=',
    field: 'amounts_payable',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  effect_object: {
    operator: '=',
    field: 'effect_object',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  amountsPayableLT: {
    operator: '<=',
    field: 'amounts_payable',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  amountActuallyPaidGT: {
    operator: '>=',
    field: 'amount_actually_paid',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  amountActuallyPaidLT: {
    operator: '<=',
    field: 'amount_actually_paid',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  refundAmountGT: {
    operator: '>=',
    field: 'refund_amount',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  refundAmountLT: {
    operator: '<=',
    field: 'refund_amount',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  couponIdEQ: {
    operator: '=',
    field: 'coupon_id',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  createTimeGT: {
    operator: '>=',
    field: 'create_time',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  createTimeLT: {
    operator: '<=',
    field: 'create_time',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  payTimeGT: {
    operator: '>=',
    field: 'pay_time',
    value: '',
    alias: 'ord',
    dataType: 'date',
  },
  payTimeLT: {
    operator: '<=',
    field: 'pay_time',
    value: '',
    alias: 'ord',
    dataType: 'date',
  },
  refundTimeGT: {
    operator: '>=',
    field: 'refund_time',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  refundTimeLT: {
    operator: '<=',
    field: 'refund_time',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  cancelTimeGT: {
    operator: '>=',
    field: 'cancel_time',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  cancelTimeLT: {
    operator: '<=',
    field: 'cancel_time',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  statusEQ: {
    operator: '=',
    field: 'status',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  paymentAgencyShortNameEQ: {
    operator: '=',
    field: 'payment_agency_short_name',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  thirdPartyOrderIdEQ: {
    operator: '=',
    field: 'third_party_order_id',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  shopIdEQ: {
    operator: '=',
    field: 'shop_id',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  amountActuallyPaidORDER: {
    operator: 'order',
    field: 'amount_actually_paid',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  createTimeORDER: {
    operator: 'order',
    field: 'create_time',
    value: '',
    alias: 'ord',
    dataType: 'number',
  },
  payTimeORDER: {
    operator: 'order',
    field: 'pay_time',
    value: '',
    alias: 'ord',
    dataType: 'string',
  },
  limit: { page: 1, limit: 20 },
  // group: {
  //     operator: "group",
  //     field: "id",
  //     value: "",
  //     alias: "cpul",
  //     dataType: "string",
  // },
  fold: false,
  type: 'parking',
  paymentAgencyOptions: [],
  payTimeArr:[],
  userOptions: [],
  statusOptions: [
    { key: 0, name: '待支付' },
    { key: 1, name: '已支付' },
    { key: 2, name: '已发货' },
    { key: 3, name: '已取消' },
    { key: 4, name: '退款中' },
    { key: 5, name: '已退款' },
    { key: 6, name: '退货中' },
    { key: 7, name: '已退货' },
    { key: 8, name: '交易成功' },
    { key: 9, name: '售后中' },
    { key: 10, name: '售后已结束' },
  ],
  goodsTypeOptions: [
    { key: 1, name: '停车' },
    { key: 2, name: '充值提现' },
    { key: 3, name: '商城' },
    { key: 4, name: '转账收款' },
    { key: 5, name: '停车套餐' },
    { key: 6, name: '车位预订' },
    { key: 7, name: '优惠券充值' },
  ],
  tableSortRef: null,
  stripe: false,
  // listLoading: false,
  border: true,
  // list: [],
  lineHeight: 'large',
  selectRows: '',
  // total: 0,
  // layout: 'total, sizes, prev, pager, next, jumper',
  checkList: ['商品名', '应付金额', '代付金额', '实付金额', '状态', '支付时间'],
  columns: [
    {
      label: '商品名',
      prop: 'goodsName',
    },
    {
      label: '应付金额',
      prop: 'amountsPayable',
      sortable: true,
    },
    {
      label: '代付金额',
      prop: 'collectionAmount',
      sortable: true,
    },
    {
      label: '实付金额',
      prop: 'amountActuallyPaid',
      sortable: true,
    },
    {
      label: '商品类型',
      prop: 'goodsType',
    },
    {
      label: '状态',
      prop: 'newStatus',
    },
    {
      label: '支付机构',
      prop: 'newPaymentAgencyShortName',
    },
    {
      label: '操作人id',
      prop: 'handleUid',
    },
    {
      label: '店铺id',
      prop: 'shopId',
    },
    {
      label: '支付时间',
      prop: 'newPayTime',
    },
    {
      label: '退款时间',
      prop: 'refundTime',
    },
    {
      label: '退款金额',
      prop: 'refundAmount',
    },
    {
      label: '取消时间',
      prop: 'cancelTime',
    },
  ],
  inTimeData: [],
  outTimeData: [],
};
