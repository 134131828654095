<template>
  <div class="home">
    <div className="page flex-col">
      <div className="bd1 flex-col">
        <!-- 头部组件 -->
        <Header ref="header" />
        <div className="layer3 flex-row">
          <ParkingLotList />

          <!-- 出入场大图/视频 -->
          <VideoList />

          <!-- 入场信息 -->
          <InParkInfo />

          <!-- 出场缴费信息 -->
          <!-- <ChargeBox @countPayAmountByDuty="countPayAmountByDuty" /> -->

          <!-- 出场信息 -->
          <ParkingFee />
        </div>
        <div className="layer6 flex-row">
          <!-- 最近出入场记录 -->
          <EntryExitRecords />

          <!-- 出入场小图 -->
          <EntryAndExitPicture />
        </div>
      </div>
    </div>
    <LicenseplateCorrection />
    <!-- 停车场列表选择开关 -->
    <div class="selectParkingLot" @click="setParkSwitch">选择停车场</div>
  </div>
</template>

<script>
import Header from "../components/Header";
import ParkingLotList from "../components/ParkingLotList";
import LicenseplateCorrection from "../components/LicenseplateCorrection/LicenseplateCorrection";
import VideoList from "../components/VideoList";
// import ChargeBox from "../components/ChargeBox";
import { getCarParkList } from "@/network/api";
import EntryExitRecords from "../components/EntryExitRecords";
import EntryAndExitPicture from "../components/EntryAndExitPicture";
import ParkingFee from "../components/ParkingFee";
import InParkInfo from "../components/InParkInfo";
import { InitWebSocket } from "@/utils/websocket";
import { mapState } from "vuex";
import store from "../store";
export default {
  name: "Home",
  data() {
    return {
      queryFrom: {
        page: 1,
        size: 50,
      },
    };
  },
  components: {
    Header,
    ParkingLotList,
    VideoList,
    // ChargeBox,
    EntryExitRecords,
    EntryAndExitPicture,
    LicenseplateCorrection,
    ParkingFee,
    InParkInfo,
  },
  computed: {
    ...mapState(["parkSwitch"]),
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      InitWebSocket();
    }
    this.getCarParkList();
  },
  methods: {
    setParkSwitch() {
      store.commit("SETPARKLISTSWITCH", !this.parkSwitch);
    },
    countPayAmountByDuty() {
      this.$refs.header.countPayAmountByDuty();
    },

    // 获取该账号下的停车场
    async getCarParkList() {
      var { data, code } = await getCarParkList(this.queryFrom);
      if (code == 10002) {
        this.$store.commit("SETCARPARKLIST", data);
      }
    },
  },
};
</script>

<style scoped>
.selectParkingLot {
  position: absolute;
  width: 30px;
  background: red;
  left: 0;
  top: 110px;
  z-index: 2;
  color: #fff;
  text-align: center;
}
</style>
