<template>
  <el-dialog v-model="dialogVisible" title="实时视频" @close="close" fullscreen :destroy-on-close="true">
    <div class="box">
      <!-- <div class="video-box" v-for="(item, i) in flvUrlList" :key="i">
        <flv :url="item"></flv>
        
      </div> -->
      <!-- <el-image
              :id="'show_video'"
              className="pic1-0"
              :src="require('../../assets/image/BG@2x.png')"
            /> -->
      <!-- <FLV  url="ws://192.168.0.120:9080/ws.flv?token=I%2F9qnUE6hgj2G0GUf063D3RqecRu71L79KlyMLytz9p%2FQIy3Ot9NLHMQwAbiM49CDkLRfAR4wxj6K2wIh67bq1czIh9S6P83Irn3uNiYrXd8jFlZcABQULf5X2zVneFKsceKLFk89JsUopN0wvkOUwnKvNYbh5lyNQvjcxjTcK4%3D"/> -->
    </div>
    <p>内网播放设备FLV视频DEMO</p>
    <div>
      <div id='video' style="width:500px;height:300px;"></div>
    </div>
  </el-dialog>
</template>

<script>
import { JSEncrypt } from 'jsencrypt'
import { Base64 } from 'js-base64'
import { getLocalZhenShiToken } from '@/network/api/index'
import { mapState } from "vuex";
// import flv from "../flv";
// import ReconnectingWebSocket from "reconnecting-websocket";
// import axios from "axios";
export default {
  // components: { flv },
  computed: {
    ...mapState(["carParkList", "carParkIndex", "deviceList"]),
  },
  data() {
    return {
      // 是否显示本组件
      dialogVisible: false,
      flvUrlList: [],
      player: null
    };
  },
  methods: {
    initVideo(id, url) {
      const that = this
      var container = document.getElementById(id)
      that.player = new window.Jessibuca({ container: container, videoBuffer: 0.2, isResize: false, forceNoOffscreen: true, isNotMute: false, timeout: 10 })
      that.player.on('load', function () {
        that.player.play(url)
      })
      that.player.on('error', function () {
        alert("播放错误")
      })
      that.player.on('timeout', function () {
        alert("播放超时")
      })
    },
    // getLocalVideo_ZhenShi(info, index) {
    //   console.log(info.ip, index);
    //   if (window.WebSocket && info.ip) {
    //     this.socket = new ReconnectingWebSocket(`ws://${info.ip}:9080/ws:`);
    //     //收到消息
    //     console.log(123)
    //     this.socket.onmessage = function (data) {
    //       console.log(data,555)
    //       let videoImage = new FileReader();
    //       videoImage.onload = function (e) {
    //         if (e.target.readyState == 2) {
    //           console.log('e.target.result',e.target.result)
    //           if (!e.target.result) return;
    //           document
    //             .getElementById(`show_video`)
    //             .setAttribute("src", e.target.result);
    //         }
    //       };
    //       videoImage.readAsDataURL(data.data);
    //     };
    //     //连接打开
    //     this.socket.onopen = function (e) {
    //       console.info("臻识本地视频连接开启", e);
    //     };
    //     //连接断开
    //     this.socket.onclose = function (e) {
    //       console.log("websocket.onclose", e.code);
    //     };
    //   }
    // },
    // 显示本组件
    showDialog() {
      console.log(new JSEncrypt().setPublicKey(), 123)
      // const that = this;
      this.dialogVisible = true;
      // 获取关卡臻识Token
      // this.deviceList.forEach((item,index) => {
      //   console.log(item,index)
      // }); 
      var ip = "192.168.0.120";   // 设备局域网IP
      var port = 80;              // 设备网页端口
      var proto = "http";         // 协议，http 或 https
      // var username = "admin";     // 设备网页登录用户名
      // var password = "admin";     // 设备网页登录密码

      var url = proto + "://" + ip + ":" + port + "/request.php"
      var data = {
        type: 'get_live_stream_type',
        module: 'BUS_WEB_REQUEST'
      }

      this.getZhenShiWS(data, url, ip)


      // 请求flv链接
      // for (let i = 0; i < this.deviceList.length; i++) {
      //   console.log(this.deviceList[i].barCode)
      //   axios({
      //     method: "get",
      //     url:
      //       "https://sjzt.jxwenxin.com/prod-api/rtsp/carStream/getCarStreamVideo?sn=" +
      //       this.deviceList[i].barCode,
      //     headers: {
      //       Authorization:
      //         "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX2tleSI6ImZkZTQ0ODkxLWY4YWUtNDFmOS04ODY3LTNiMzEyNGNjZDJlZSIsInVzZXJuYW1lIjoiYWRtaW4ifQ.hCFixMVQRc8d4rDHukgOfhvTTrQiouBtpZt7ioEK9papeg9PeTrYtofXGVoiUcvtfdiZATVY3Mjl0OHHI8Itig",
      //     },
      //   })
      //     .then(function(response) {
      //       that.flvUrlList.push(response.data.url);
      //     })
      //     .catch(function(error) {
      //       console.log(error);
      //     });
      // }
      // this.getLocalVideo_ZhenShi({ip:'192.168.0.120'},1)
    },
    async getZhenShiWS(data, url, ip) {
      const { err_msg, body, state, type } = await getLocalZhenShiToken(data, url)
      console.log(err_msg, body, state, type)
      if (state !== 200) {
        alert("该设备不支持FLV")
      } else {
        let pubkey = Base64.decode(body.pubkey)
        let encryptStr = new JSEncrypt()
        encryptStr.setPublicKey(pubkey)
        let video_port = body.port
        // var pubkey = Base64.decode(body.pubkey)
        // let encryptStr = new JSEncrypt()
        // encryptStr.setPublicKey(pubkey)
        var str = 'admin' + ':' + 'admin'
        var token = encodeURIComponent(encryptStr.encrypt(str.toString()))

        var video_url = "ws://" + ip + ":" + video_port + "/ws.flv?token=" + token;
        console.log(video_url)
        this.initVideo("video", video_url)
      }
    },
    // 关闭本组件
    close() {
      this.flvUrlList = [];
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  flex-wrap: wrap;
}
.video-box {
  width: 800px;
  height: auto;
  margin-left: 10px;
  margin-top: 10px;
}
</style>
