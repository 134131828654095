<template>
  <!-- <div className="group1 flex-col"> -->
  <div
    className="group1 flex-col"
    :style="{ width: parkSwitch ? '16%' : '0px' }"
  >
    <!-- <div className={index === tabIndex ? 'group3 flex-col' : index % 2 == 0 ? 'group5 flex-row' : 'group6 flex-row'} key={index}> -->
    <div
      :class="
        index === carParkIndex
          ? 'group3 flex-col'
          : index % 2 == 0
          ? 'group5 flex-row'
          : 'group6 flex-row'
      "
      v-for="(item, index) in carParkList"
      :key="item.id"
      @click="onList(index)"
    >
      <div className="outer1 flex-row">
        <span className="info3">{{ item.name }}</span>
        <img alt="" className="label1" src="../../assets/arrow.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCheckPointByQuery } from "@/network/api";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["parkSwitch", "carParkIndex", "carParkList"]),
  },
  data() {
    return {
      // queryFrom: {
      //   page: 1,
      //   size: 50,
      // },
      // carParkList1: [],
    };
  },
  created() {
    // this.getCarParkList();
    // this.carParkList1 = this.carParkList;
  },
  watch: {
    carParkList: function() {
      this.getCheckPointByQuery();
    },
  },
  methods: {
    onList(index) {
      this.$store.commit("SetCarParkIndex", index);
      this.getCheckPointByQuery();
    },
    // async getCarParkList() {
    //   var { data, code } = await getCarParkList(this.queryFrom);
    //   if (code == 10002) {
    //     console.log(data);
    //     this.carParkList = data;
    //     this.$store.commit("SETCARPARKLIST", data);
    //     console.log("提交完成");
    //   }
    // },
    async getCheckPointByQuery() {
      var { data, code } = await getCheckPointByQuery({
        page: 1,
        size: 100,
        carParkId: this.carParkList[this.carParkIndex].id,
      });
      // console.log(data,111)
      if (code != 10002) return;
      this.$store.commit("SETCHECKPOINTLIST", data);
    },
  },
};
</script>
