import { createStore } from "vuex";
import createVuexAlong from "vuex-along"; // vuex缓存工具
export default createStore({
  state: {
    carParkList: [],
    deviceList: [],
    carParkIndex: 0,
    userinfo: "",
    checkPointList: [],
    inCar: [],
    bigImage: [],
    outCar: [],
    chargeCar: [],
    carImage: {
      inImage: "",
      outImage: "",
      inSmallImage: "",
      outSmallImage: "",
    },
    // 今日现金收费金额
    totalCashCharge: 0,
    paidCar: {},
    parkSwitch: false,
    licenseplateCorrection: {
      show: false,
      date: null,
    },
    globalConfig: {
      promptEntryExitCar: true,
      promptPayCar: true,
      promptChargeOutCar: false,
      inOutImageType: true, // false为大图 true为小图
    },
    //出口列表信息
    exitInfoList: [],
    //替换出口列表信息的索引
    exitInfoIndex: 0,
    //入口列表信息
    inInfoList: [],
    //替换入口列表信息的索引
    inInfoIndex: 0,
  },
  mutations: {
    // 设置出场信息
    SETEXITINFOLIST(state, payload) {
      //如果exitInfoList没有数据
      if (state.exitInfoList.length == 0) {
        state.exitInfoList.push(payload);
        // console.log("没有数据-push", state.exitInfoList);
      } else {
        //如果exitInfoList有数据
        //判断是否有相同的出口
        let isSame = false;
        for (let i = 0; i < state.exitInfoList.length; i++) {
          if (
            state.exitInfoList[i].outCheckpoint?.id == payload.outCheckpoint?.id
          ) {
            isSame = true;
            state.exitInfoIndex = i;
            //如果有相同的出口，替换
            state.exitInfoList[i] = payload;
            // console.log("有数据-找到替换后", state.exitInfoList);
          }
        }
        if (!isSame) {
          // state.exitInfoList.push(payload)
          state.exitInfoIndex = 0;
          state.exitInfoList.unshift(payload);
          // console.log("有数据-没有找到-push", state.exitInfoList);
        }
      }
    },

    // 设置入场信息
    SETININFOLIST(state, payload) {
      // console.log("入口信息", payload);
      //如果inInfoList没有数据
      if (state.inInfoList.length == 0) {
        state.inInfoList.push(payload);
        // console.log("没有数据-push", state.inInfoList);
      } else {
        //如果inInfoList有数据
        //判断是否有相同的入口
        let isSame = false;
        for (let i = 0; i < state.inInfoList.length; i++) {
          if (
            state.inInfoList[i].cfCarParkCheckpointIn.id ==
            payload.cfCarParkCheckpointIn.id
          ) {
            isSame = true;
            state.inInfoIndex = i;
            //如果有相同的入口，替换
            state.inInfoList[i] = payload;
            // console.log("有数据-找到替换后", state.inInfoList);
          }
        }
        if (!isSame) {
          // state.inInfoList.push(payload)
          state.inInfoIndex = 0;
          state.inInfoList.unshift(payload);
          // console.log("有数据-没有找到-push", state.inInfoList);
        }
      }
    },

    // 设置出入场大图
    SETBIGIMAGE(state, payload) {
      if (payload.outTime == 0) {
        // 入场
        const checkPoint = state.bigImage.find(
          (item) => item.id == payload.inCheckPointId
        );
        if (checkPoint == undefined) {
          state.bigImage.push({
            id: payload.inCheckPointId,
            image: payload.inBigImage,
          });
        } else {
          checkPoint.image = payload.inBigImage;
        }
      } else {
        // 出场
        const checkPoint = state.bigImage.find(
          (item) => item.id == payload.outCheckPointId
        );
        if (checkPoint == undefined) {
          state.bigImage.push({
            id: payload.outCheckPointId,
            image: payload.outBigImage,
          });
        } else {
          checkPoint.image = payload.outBigImage;
        }
      }

      // // 查找停车场是否已存在
      // const carPark = state.bigImage.find(
      //   (item) => item.carParkId == payload.carParkId
      // );
      // // 如果停车场不存在则添加
      // if (carPark == undefined) {
      //   state.bigImage.push({
      //     carParkId: payload.carParkId,
      //     carParkCheckpoint: [
      //       {
      //         id:
      //           payload.outTime == 0
      //             ? payload.inCheckPointId
      //             : payload.outCheckPointId,
      //         image:
      //           payload.outTime == 0 ? payload.inBigImage : payload.outBigImage,
      //       },
      //     ],
      //   });
      //   console.log("停车场不存在，添加的停车场为：", payload.carParkId);
      //   return;
      // }
      // console.log("停车场存在：", carPark.carParkId);
      // // 如果停车场存在则查找关卡是否存在
      // // 判断为出场还是入场信息
      // let checkPoint = null;
      // if (payload.outTime == 0) {
      //   checkPoint = carPark.carParkCheckpoint.find(
      //     (item) => item.id == payload.inCheckPointId
      //   );
      //   // 如果关卡不存在则添加
      //   if (checkPoint == undefined) {
      //     console.log("入口关卡不存在，添加的关卡为：", payload.inCheckPointId);
      //     carPark.carParkCheckpoint.push({
      //       id: payload.inCheckPointId,
      //       image: payload.inBigImage,
      //     });
      //   } else {
      //     // 关卡存在则替换图片
      //     console.log("入口关卡存在，替换图片");
      //     checkPoint.image = payload.inBigImage;
      //   }
      // } else {
      //   checkPoint = carPark.carParkCheckpoint.find(
      //     (item) => item.id == payload.outCheckPointId
      //   );
      //   // 如果关卡不存在则添加
      //   if (checkPoint == undefined) {
      //     carPark.carParkCheckpoint.push({
      //       id: payload.OutCheckPointId,
      //       image: payload.outBigImage,
      //     });
      //   } else {
      //     // 关卡存在则替换图片
      //     console.log("出场关卡存在，替换图片");
      //     checkPoint.image = payload.outBigImage;
      //   }
      // }
    },
    SETTOTALCASHCHARGE(state, payload) {
      state.totalCashCharge = payload;
    },
    SETCARPARKLIST(state, payload) {
      state.carParkList = payload;
    },
    SETDEVICELIST(state, payload) {
      state.deviceList = payload;
    },
    SETCHECKPOINTLIST(state, payload) {
      state.checkPointList = payload;
    },

    // 更新车牌号
    SETALLINCAR(state, payload) {
      state.inCar = payload;
    },

    // 设置入场信息
    SETIN(state, payload) {
      if (state.inCar.length > 14) {
        state.inCar.pop();
      }
      state.inCar.unshift(payload);
    },

    // 设置出场信息
    SETOUT(state, payload) {
      // console.log(state, payload,'666')
      if (state.outCar.length > 14) {
        state.outCar.pop();
      }
      state.outCar.unshift(payload);
    },
    SETCHARGECAR(state, payload) {
      state.chargeCar = payload;
    },

    // 设置入场图片
    SETINIMAGE(state, payload) {
      state.carImage.inImage = payload.inImage;
      state.carImage.inSmallImage = payload.inSmallImage;
    },

    // 设置出场图片
    SETOUTIMAGE(state, payload) {
      state.carImage.outImage = payload.outImage;
      state.carImage.outSmallImage = payload.outSmallImage;
    },

    PAIDCAR(state, payload) {
      state.paidCar = payload;
    },
    SETPARKLISTSWITCH(state, payload) {
      state.parkSwitch = payload;
    },
    SETCONFIG(state, payload) {
      state.globalConfig = payload;
    },
    SetCarParkIndex(state, payload) {
      state.carParkIndex = payload;
    },
    SetLicenseplateCorrection(state, payload) {
      state.licenseplateCorrection = {
        show: payload.show,
        data: payload.data,
      };
    },
  },
  getters: {
    carParkId: (state) =>
      state.carParkList.length > 0
        ? state.carParkList[state.carParkIndex].id
        : "",
  },
  actions: {},
  modules: {},
  plugins: [
    createVuexAlong({
      name: "vuex-along",
    }),
  ],
});
