<template>
  <div className="box5 flex-col">
    <div className="section11 flex-col">
      <span className="info13">最近入场记录</span>
      <div className="layer7 flex-col">
        <div className="mod2 flex-row">
          <span className="word26">车牌号</span>
          <span className="word27">车辆类型</span>
          <span className="word28">入场时间</span>
          <span className="txt7">入场方式</span>
          <span className="txt7">入场备注</span>
          <span className="word29">其他</span>
        </div>
      </div>
      <div className="layer9 flex-col" v-for="item in inCarFltter" :key="item.id">
        <div className="section12 flex-row">
          <span className="word34" style="cursor: pointer;color:rgb(194 88 79);font-weight:700" @click="getCarInfo(item.numberPlate,item.releaseNotice,item.carParkId,item.cfCarParkCarType.name)">{{
            item.numberPlate
          }}</span>
          <div className="outer5 flex-col">
            <span className="word35">{{ item.cfCarParkCarType?.name }}</span>
          </div>
          <span className="info14">{{ getTime(item?.inTime) }}</span>

          <span className="word36">{{
            getReleaseText(item.inReleaseType)
          }}</span>
          <span className="word36">
            <el-tooltip :content="item.releaseNotice" placement="top">
              <span style="display: block;width: 50px;overflow: hidden;text-wrap: nowrap;text-overflow: ellipsis;">{{item.releaseNotice}}</span>
            </el-tooltip>
          </span>
          <span className="txt9" @click="editCar(item)">纠错</span>
        </div>
      </div>
    </div>
  </div>

  <div className="box7 flex-col">
    <div className="wrap5 flex-col">
      <span className="word58">最近出场记录</span>
      <div className="main4 flex-col">
        <div className="outer8 flex-row">
          <span className="word59">车牌号</span>
          <span className="word60">车辆类型</span>
          <span className="word61">入场时间</span>
          <span className="word62">出场时间</span>
          <span className="txt23">出场备注</span>
          <span className="txt23">出场方式</span>
        </div>
      </div>
      <div className="main6 flex-col" v-for="item in outCarFltter" :key="item.id">
        <div className="main7 flex-row">
          <!-- <span className="word65" @click="getInfo(item.cfCarParkUseLog)">{{
            item.cfCarParkUseLog.numberPlate
          }}</span> -->
          <span className="word65" style="cursor: pointer;color:rgb(194 88 79);font-weight:700"
           @click="getCarInfo(item.numberPlate||item.cfCarParkUseLog
.numberPlate,item.carParkUseLog?.releaseNotice,item.carParkId||item.cfCarPark.
id,item.cfCarParkCarType.name)">{{
            item.cfCarParkUseLog?.numberPlate||item.numberPlate
          }}</span>
          <div className="bd14 flex-col">
            <span className="word66">{{ item.cfCarParkCarType?.name }}</span>
          </div>
          <span className="info28">{{
            getTime(item.cfCarParkUseLog?.inTime||item.inTime)
          }}</span>
          <span className="info29">{{
            getTime(item.cfCarParkUseLog?.outTime||item.outTime)
          }}</span>
          <span className="word36">
            <el-tooltip :content="item.carParkUseLog?.releaseNotice" placement="top">
              <span style="display: block;width: 50px;overflow: hidden;text-wrap: nowrap;text-overflow: ellipsis;">{{item.carParkUseLog?.releaseNotice}}</span>
            </el-tooltip>
          </span>
          <span className="word67">{{
            getReleaseText(item.cfCarParkUseLog?.outReleaseType||item.outReleaseType)
          }}</span>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogTableVisible" :title="dialogTitle" width="40%" center style="background: #15142d;">
      <!-- <el-table :data="gridData">
        <el-table-column property="date" label="Date" width="150" />
        <el-table-column property="name" label="Name" width="200" />
        <el-table-column property="address" label="Address" />
      </el-table> -->
      <!-- <p v-for="o in 4" :key="o" class="text item">{{ 'List item ' + o }}</p> -->
      <div v-if="carInfo?.length" style="display:flex;justify-content: space-around;">
        <el-card style="max-width: 480px" v-for="(item,index) in carInfo" :key="index">
          <template #header>
            <div class="card-header" style="display:flex;justify-content: space-between; width: 100%;}">
              <span>{{item.numberPlate}}</span><span v-if="item.numberPlate == currNumberPlate"><el-tag type="primary" size="small">当前车辆</el-tag></span><span v-else>
                <el-tag type="success" size="small" v-if="item.isInOut==0">未入场</el-tag><el-tag type="danger" size="small" v-if="item.isInOut==1">已入场</el-tag>
              </span>
            </div>
          </template>
          <div class="line-style">
            <span>所属车场:</span><span>{{item.carParkName}}</span>
          </div>
          <div class="line-style">
            <span>所属车位:</span><span style="margin-right:10px">{{item.parkingSpaceLocation}}-{{item.parkingSpaceId}}</span><span v-if="item.vehicleType==1"><el-tag  round  effect="plain" size="small">普通车位</el-tag></span><span v-if="item.vehicleType==2"><el-tag round  effect="plain" size="small">子母车位</el-tag> </span><span v-if="item.vehicleType==3"><el-tag type="warning" round  effect="plain" size="small"></el-tag>VIP车位</span>
          </div>
          <!-- <div class="line-style">
            <span>车位车辆:</span><span></span>
          </div> -->
          <div class="line-style">
            <span>业主姓名:</span><span>{{item.carOwnerName}}</span>
          </div>
          <div class="line-style" v-if="phones?.length">
            <div v-for="(phone,i) in item.phones" :key="i">
              <span>联系方式{{i+1}}:</span><span>{{phone}}</span>
            </div>
          </div>
          <div class="line-style" v-else>
            <div >
              <span>联系方式:</span><span>{{'车主未留存联系方式'}}</span>
            </div>
          </div>
          <div class="line-style">
            <span>进出备注:</span><span v-if="item.numberPlate == currNumberPlate" style="color:#fc4408">{{releaseNotice}}</span>
          </div>
          <template #footer>Footer content</template>
        </el-card>
      </div>
      <div v-else style="display:flex;justify-content: space-around;">
        <!-- 不是数组 -->
        <el-card style="max-width: 480px">
          <template #header>
            <div class="card-header" style="display:flex;justify-content: space-between; width: 100%;}">
              <span>{{carInfo.numberPlate}}</span><span v-if="carInfo.numberPlate == currNumberPlate"><el-tag type="primary" size="small">当前车辆</el-tag></span><span v-else>
                <el-tag type="success" size="small" v-if="carInfo.isInOut==0">未入场</el-tag><el-tag type="danger" size="small" v-if="carInfo.isInOut==1">已入场</el-tag>
              </span>
            </div>
          </template>
          <div class="line-style">
            <span>车辆类型:</span><span>{{carTypeName}}</span>
          </div>
          <div class="line-style">
            <span>进出备注:</span><span v-if="carInfo.numberPlate == currNumberPlate">{{releaseNotice}}</span>
          </div>
          <template #footer>Footer content</template>
        </el-card>
      </div>
      <template #footer>
        <!-- <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">
          关闭
        </el-button>
      </div> -->
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { $timeFormat } from "../../utils/index";
import { ElMessageBox, ElMessage } from "element-plus";
import { dutyerUpdateUseLog, getOwnerInformation } from "@/network/api";
import { cloneDeep } from "lodash";
import store from "@/store/index";

export default {
  data() {
    return {
      carParkReleaseType: [
        { key: 0, name: "未知" },
        { key: 1, name: "自动抬杆" },
        { key: 2, name: "人工抬杆" },
        { key: 3, name: "手机放行" },
      ],
      dialogTableVisible: false,
      carInfo: {},
      dialogTitle: '',
      releaseNotice: '',
      currNumberPlate: '',
      carTypeName: ''
    };
  },
  computed: {
    ...mapState(["inCar", "outCar"]),
    ...mapGetters(["carParkId"]),
    inCarFltter() {
      return this.inCar.filter((i) => i.carParkId === this.carParkId);
    },
    outCarFltter() {
      return this.outCar.filter((i) => i.cfCarPark.id === this.carParkId);
    },
  },
  methods: {
    getTime(time) {
      // console.log(time,666)
      return $timeFormat(time, "MM-DD HH:mm:ss");
    },
    async getCarInfo(numberPlate, releaseNotice, carParkId, carTypeName) {
      // console.log(numberPlate)
      this.currNumberPlate = numberPlate
      this.carTypeName = carTypeName
      const { code, data ,message} = await getOwnerInformation({
        numberPlate: numberPlate, carParkId: carParkId
      })
      if (code == 10002) {
        this.carInfo = data
        this.dialogTitle = numberPlate + '相关车辆信息'
        this.releaseNotice = releaseNotice
        this.dialogTableVisible = true
      }else{
        ElMessage({
            type: "error",
            message,
          });
      }
      console.log(code, data)
    },
    // 获取车牌号
    async getInfo(value) {
      console.log("value", value);
      // let conditions = {
      //   like: {
      //     list: {
      //       id: {
      //         "0": {
      //           value: value.id,
      //           alias: "cpul",
      //         },
      //       },
      //     },
      //     operator: "like",
      //   },
      //   order: {
      //     list: {
      //       create_time: {
      //         type: "desc",
      //         alias: "cpul",
      //       },
      //     },
      //     operator: "order",
      //   },
      //   limit: {
      //     operator: "limit",
      //     page: 1,
      //     limit: 20,
      //   },
      // };
      // const res = await getNewOutLog({
      //   conditions: conditions,
      // });
      // console.log("查询结果",res);
      // store.commit("SETNEWINFO", res.data[0]);
    },
    getReleaseText(type) {
      return this.carParkReleaseType.filter((i) => i.key == type)[0]?.name;
    },
    editCar(item) {
      ElMessageBox.prompt("请输入修改后的车牌号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(async ({ value }) => {
        var { code, message } = await dutyerUpdateUseLog({
          numberPlate: value,
          carParkUseLogId: item.id,
        });
        if (code === 10002) {
          // 修改车牌后 改变本地的数据
          var list = cloneDeep(this.inCar);
          list.map((i) => {
            if (i.id === item.id) {
              i.numberPlate = value;
            }
          });
          store.commit("SETALLINCAR", list);
          ElMessage({
            type: "success",
            message,
          });
        } else {
          ElMessage({
            type: "error",
            message,
          });
        }
      });
    },
  },
  watch: {
    // inCar: function (value) {
    //   console.log("value", value);
    // },
  },
};
</script>
<style>
.line-style {
  line-height: 30px;
  height: 30px;
}
</style>