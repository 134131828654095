<template>
  <div className="group15 flex-col" :style="{
      width: parkSwitch ? '43%' : '60%',
      marginLeft: parkSwitch ? '20px' : '0px',
    }">
    <div className="bd6 flex-row">
      <div className="box3 flex-row">
        <div className="group16-0 flex-col" v-for="item in deviceList" :key="item.id">
          <div className="layer5-0 flex-col" style="position: relative;">
            <div style="marginBottom:10px"><span className="word11-0" style="fontWeight:700">{{ item.title }}</span></div>
            <!-- <el-image className="pic1-0" :src="attachImageUrl(item.src)" /> -->
            <div style="position: relative;display:flex;justify-content: center;height: 100%;padding:0 10px;">
              <FLV className="" :url="item.flvUrl" style="position:absolute;height: 88%;width: 100%;padding:0 10px 0 10px;" v-if="item.flvUrl&&item.videoMode=='remote'">{{item.message}}</FLV>
              <div v-else-if="!item.flvUrl&&item.message" style="width: 100%; height: 300px;line-height:300px;text-align: center;font-weight: 600;color: #fc4408;  overflow: hidden;text-overflow: ellipsis;
    white-space: nowrap;padding:0 10px;">{{item.message}}</div>
              <div :id="`video${item.barCode}`" style="height: 88%;" v-else-if="item.videoMode=='local'&&!item.successPlay" v-loading="loading" element-loading-text="Loading..."></div>
              <div :id="`video${item.barCode}`" style="height: 88%;" v-else-if="item.videoMode=='local'&&item.successPlay==false" v-loading="loading" element-loading-text="Loading...">{{item.message}}</div>
              <!-- <el-image v-else className="pic1-0" :src="require('../../assets/image/BG@2x.png')" /> -->
            </div>
            <div style="position: absolute;width: 136px; height: 260px;bottom: -30%;left: 2%;">
              <el-image :max-scale="6" fit="cover" :image-viewer="true" :preview-src-list="[item.src ]" :src="item.src || '../../assets/image/BG@2x.png'">
                <template #error>
                  <div class="image-slot" v-if="item.direction == 'in'">暂无入场抓拍图片</div>
                  <div class="image-slot" v-else-if="item.direction == 'out'">暂无出场抓拍图片</div>
                  <div class="image-slot" v-else>暂无抓拍图片</div>
                </template>
              </el-image>
            </div>
            <div className="section4-0  " style="position: absolute;bottom: 10px;display:flex;justify-content: flex-start;">
              <div className="block1-1  flex-col videoBtn" style="width:20%;marginLeft:10px;" @click="onVideoIndex(item, 'release_')">
                <span className="word12-0">开闸放行</span>
              </div>

              <div className="block1-1 flex-col videoBtn" style="width:20%;marginLeft:10px;" @click="onCapture(item)">
                <span className="word12-0">抓拍</span>
              </div>
              <!-- <div className="block2-0 flex-col" @click="onVideoIndex(item, 'checkouPoint_off_')">
                <span className="txt1-0">关闸</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from 'jsencrypt'
import { Base64 } from 'js-base64'
import { mapState } from "vuex";
import { reactive, toRaw } from "vue";
import { getCarparkDeviceAdmin, openOrCloseDoor, capture, getLocalZhenShiToken } from "@/network/api";
import FLV from '../flv/index.vue'
import axios from "axios";
import { ElMessage } from "element-plus";
// import PElImage from '@/components/p-el-image';
// import ReconnectingWebSocket from "reconnecting-websocket";
export default {
  components: {
    FLV,
    // PElImage,
  },
  data() {
    return {
      deviceList: [],                // 硬件列表
      remoteVideoList: reactive([]), // 远程视频列表
      localVideoList: reactive([]),  // 本地视频列表
      showFlv: reactive(true),       // 是否显示 Flv
      socket: null,                  // WebSocket 连接
      videoPlayers: {},              // 存储每个视频的 player 实例
      loading: reactive(true),       // 本地视频是否加载中
    }
  },
  computed: {
    ...mapState(["checkPointList", "parkSwitch", "bigImage"]),
  },
  watch: {
    checkPointList: {
      handler: function (value) {
        this.getHardwareList(toRaw(value));
      },
      deep: true, // 开启深度侦听
    },
    bigImage: {
      handler: function (value) {
        this.deviceList.forEach((item) => {
          value.forEach((item2) => {
            if (item.checkpointId == item2.id) {
              item.src = item2.image;
            }
          });
        });

        // // 判断是进场还是出场
        // if (JSON.stringify(value.cfCarParkCheckpointOut) == "{}") {
        //   // 进场
        //   // 遍历硬件列表找到关卡ID相等的，赋值出场大图
        //   this.deviceList.forEach((item) => {
        //     if (value.inCheckPointId == item.checkpointId) {
        //       item.src = value.inBigImage;
        //     }
        //   });
        // } else {
        //   // 出场
        //   // 遍历硬件列表找到关卡ID相等的，赋值出场大图
        //   this.deviceList.forEach((item) => {
        //     if (value.outCheckpoint.id == item.checkpointId) {
        //       item.src = value.cfCarParkUseLog.outBigImage;
        //     }
        //   });
        // }
      },
      deep: true, // 开启深度侦听
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 2500)
  },
  methods: {
    viewImage() {
      // console.log(123)
      // this.viewImage = false
    },
    // 图片放大功能
    showBigImage(index) {
      var mainDiv = document.getElementById(`show_video${index}`);
      if (mainDiv.classList.contains("bigImage")) {
        mainDiv.classList.remove("bigImage");
      } else {
        mainDiv.classList.add("bigImage");
      }
    },

    // 图片防盗链
    // attachImageUrl(srcUrl) {
    //   if (srcUrl !== undefined) {
    //     const a = srcUrl.replace(
    //       /^(https?:\/\/)/,
    //       "https://images.weserv.nl/?url="
    //     );

    //     console.log(a);

    //     return a;
    //   }
    // },

    // 点击抓拍
    async onCapture(item) {
      var { message } = await capture({
        sn: item.barCode,
      });
      this.$message({
        message,
        type: "success",
      });
    },

    // 获取硬件列表
    async getHardwareList(value) {
      // const that = this;
      // that.remoteVideoList = []
      var idText = value.map((i) => i.id).join(",");
      var { data, code } = await getCarparkDeviceAdmin({
        checkpointIds: idText,
      });
      if (code != 10002) return;
      var checkPointList = this.checkPointList;
      for (const i in data) {
        for (const j in checkPointList) {
          if (data[i].checkpointId == checkPointList[j].id) {
            data[i]["title"] = checkPointList[j].name;
          }
        }
      }
      this.deviceList = data;
      this.deviceList.forEach((item) => {
        this.bigImage.forEach((item2) => {
          if (item.checkpointId == item2.id) {
            item.src = item2.image;
          }
        });
      });
      // console.log(this.deviceList)
      // 远程取流方式/remote
      this.fetchRemoteDeviceList(this.deviceList.filter(deviceItem => deviceItem.videoMode == 'remote'))
      // 本地取流方式/local
      this.fetchLocalDeviceList(this.deviceList.filter(deviceItem => deviceItem.videoMode == 'local'))
      this.$store.commit("SETDEVICELIST", data);
    },
    async fetchRemoteDeviceList(deviceList) {
      console.log(deviceList, 'remote')
      this.remoteVideoList = []
      const promises = deviceList.map((device, i) =>
        axios({
          method: "get",
          url: "https://sjzt.jxwenxin.com/prod-api/rtsp/carStream/getCarStreamVideo?sn=" + device.barCode,
          headers: {
            Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX2tleSI6ImZkZTQ0ODkxLWY4YWUtNDFmOS04ODY3LTNiMzEyNGNjZDJlZSIsInVzZXJuYW1lIjoiYWRtaW4ifQ.hCFixMVQRc8d4rDHukgOfhvTTrQiouBtpZt7ioEK9papeg9PeTrYtofXGVoiUcvtfdiZATVY3Mjl0OHHI8Itig",
          },
        })
          .then(response => (
            { index: i, url: response.data.url || '', message: response.data.message || '', code: response.data.code, id: device.id }
          ))
          .catch(error => {
            console.log(error, 'error');
             ElMessage.error(error.message)
            return { index: i, url: '', message: error.message || '', code: error.code };
          })
      );

      const results = await Promise.all(promises);
      results.forEach(result => {
        // console.log(result, '远程')
        // console.log(result.id, result.url)
        this.deviceList.forEach(deviceItem => {
          if (deviceItem.id == result.id) {
            deviceItem['flvUrl'] = result.url || ''
            deviceItem['message'] = result.message || ''
          }
        })
      });
    },
    async fetchLocalDeviceList(deviceList) {
      this.localVideoList = [];
      deviceList.map(async (device, i) => {
        console.log(device, i);
        this.videoLoading = true
        let ip = device.ip;                 // 设备局域网IP
        let port = '80';                    // 设备网页端口默认80
        let proto = "http";                 // 协议，http 或 https
        let username = device.username;     // 设备网页登录用户名
        let password = device.password;     // 设备网页登录密码
        let url = `${proto}://${ip}:${port}/request.php`;
        let data = {
          type: 'get_live_stream_type',
          module: 'BUS_WEB_REQUEST'
        };
        const { body, state } = await getLocalZhenShiToken(data, url);
        if (state !== 200) {
          this.deviceList.forEach(deviceItem => {
            if (deviceItem.id == device.id) {
              deviceItem['successPlay'] = false
              deviceItem['message'] = '请检查网络与设备配置'
            }
          })
          ElMessage.error('设备不在线或该设备不支持FLV')
        } else {
          let pubkey = Base64.decode(body.pubkey)
          let encryptStr = new JSEncrypt()
          encryptStr.setPublicKey(pubkey)
          let video_port = body.port
          var str = username + ':' + password
          var token = encodeURIComponent(encryptStr.encrypt(str.toString()))
          var video_url = "ws://" + ip + ":" + video_port + "/ws.flv?token=" + token;
          this.initVideo("video" + device.barCode, video_url, device.id)
        }
      });
    },
    initVideo(containerId, url) {
      this.videoLoading = false
      var container = document.getElementById(containerId);
      // 创建一个新的 player 实例
      var player = new window.Jessibuca({
        container: container,
        videoBuffer: 0.8,
        isResize: false,
        forceNoOffscreen: true,
        isNotMute: false,
        timeout: 10,
        isFlv: true,
      });

      // 添加事件监听器
      player.on('load', function () {
        player.play(url);
      });
      player.on('error', function () {
        // alert("播放错误");
        ElMessage.error('播放错误')
        location.reload() //刷新
      });
      player.on('timeout', function () {
        // alert("播放超时");
         ElMessage.error('播放超时')
         location.reload() //刷新
      });

      // 保存 player 实例，以便后续操作
      this.videoPlayers = this.videoPlayers || {};
      this.videoPlayers[containerId] = player;
    },

    // 点击开闸放行
    async onVideoIndex(item, RedisPreFix) {
      var { message, code } = await openOrCloseDoor({
        checkPointId: item.checkpointId,
        applicationReason: RedisPreFix == "release_" ? "手动放行" : "手动关闸",
        RedisPreFix,
        type: 1,
      });
      if (code == 10002) {
        this.$message({
          message,
          type: "success",
        });
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style>
.bigImage {
  width: 90vw;
  height: 90vh;
  position: fixed;
  left: 5vw;
  top: 5vh;
  z-index: 0;
  transition: all 0.5s;
}
</style>

<style scoped>
.videoBtn:hover {
  background-color: #787483;
  cursor: pointer;
}
.image-slot {
  /* background-color: #f5f7fa; */
  background-color: #f5f7fa17;
  width: 136px;
  margin-left: 0%;
  height: 70px;
  margin-top: 10px;
  text-align: center;
  line-height: 70px;
  color: #abaeb5;
}
</style>
