<template>
  <div class="parkingFee">
    <div className="section5 flex-col">
      <div className="bd11">
        <span className="word15">入场车辆信息</span>
      </div>
    </div>
    <div style="color:#fff" class="amount_received"></div>
    <div
      style="width: 100%; hight:512px; position: static"
      v-if="inInfoList.length"
    >
      <div class="fixed-tab-bar">
        <div
          v-for="(item, index) in inInfoList.map((item) => ({
            tabName: item.cfCarParkCheckpointIn.name,
            id: item.cfCarParkCheckpointIn.id,
          }))"
          :key="index"
          @click="selectTab(index)"
          :class="{ 'active-tab': selectedTab === index }"
          style="margin-right: 10px;"
        >
          {{ item.tabName }}
        </div>
      </div>
      <div class="mainInfo">
        <div class="entranceInfo">
          <div class="infoTitle">入场信息</div>
          <div class="infoItem">
            <span>入场车牌:</span>
            <span style="font-size: 20px;">{{
              inInfoList[selectedTab]?.numberPlate || ""
            }}</span>
          </div>
          <div class="infoItem">
            <span>入场时间:</span>
            <span>{{
              formatTimestampToDateTime(inInfoList[selectedTab].inTime)
            }}</span>
          </div>
          <div class="infoItem">
            <span>入场方式:</span>
            <span>{{
              releaseType(inInfoList[selectedTab]?.inReleaseType)
            }}</span>
          </div>
        </div>
        <div class="carInfo">
          <div class="infoTitle">车辆信息</div>
          <div class="infoItem">
            <span>车辆类型:</span>
            <span>{{ inInfoList[selectedTab]?.cfCarParkCarType?.name }}</span>
          </div>
          <div class="infoItem" v-if="inInfoList[selectedTab]?.carOwnerName">
            <span>业主姓名:</span>
            <span>{{ inInfoList[selectedTab]?.carOwnerName.split("")[0]+"**" }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-if="inInfoList.length === 0"
        style="color:#fff;text-align:center;margin-top:20px"
      >
        暂无车辆数据
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    this.tabs = this.inInfoList.map((item) => ({
      tabName: item.cfCarParkCheckpointIn.name,
      id: item.cfCarParkCheckpointIn.id,
    }));
  },
  computed: {
    ...mapState(["inInfoList", "inInfoIndex"]),
  },
  //深度监听inInfoList的变化
  watch: {
    inInfoList: {
      handler: function(val) {
        this.CurrParkingInfoList = val;
        this.tabs = this.inInfoList.map((item) => ({
          tabName: item.cfCarParkCheckpointIn.name,
          id: item.cfCarParkCheckpointIn.id,
        }));
        this.selectTab(this.inInfoIndex);
      },
      deep: true,
    },
  },
  data() {
    return {
      showMask: false, // 是否显示遮罩层
      // tabs 列表
      tabs: [
        {
          tabName: "出口A",
          id: 1,
        },
        {
          tabName: "出口B",
          id: 2,
        },
      ],
      // 当前选中的tab
      selectedTab: 0,
      CurrParkingInfoList: [], //当前停车场信息
      carParkReleaseType: [
        { key: 0, name: "未知" },
        { key: 1, name: "自动抬杆" },
        { key: 2, name: "人工抬杆" },
        { key: 3, name: "手机放行" },
      ],
    };
  },
  methods: {
    /**
     * 格式化入场方式
     * @param {*} value
     */
    releaseType(value) {
      const type = this.carParkReleaseType.find((item) => item.key == value);
      return type.name;
    },

    // 选择tab
    selectTab(index) {
      // 如果当前tab已经被选中，则不执行任何操作
      if (this.selectedTab === index) {
        return;
      }
      this.selectedTab = index;
    },
    // 过滤tabs
    filteredTabs() {
      // 如果当前tab被选中，则显示
      if (this.selectedTab === 0) {
        return this.tabs;
      }
      // 否则，过滤掉当前tab
      return this.tabs.filter((item, index) => index !== this.selectedTab);
    },
    //转换时间戳
    formatTimestampToDateTime(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始，需要加 1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 计算时长
    formatDuration(startTime, endTime) {
      if (startTime > endTime) {
        [startTime, endTime] = [endTime, startTime]; // 交换时间戳，确保 startTime <= endTime
      }

      const timeDifference = endTime - startTime;
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      const remainingHours = hours % 24;
      const remainingMinutes = minutes % 60;
      const remainingSeconds = seconds % 60;

      // 格式化数字为两位数，不足两位数的部分补零
      const formatNumber = (number) => {
        return number < 10 ? `0${number}` : `${number}`;
      };

      // 构建格式化的时间间隔字符串
      const formattedDuration = `${formatNumber(days)}天${formatNumber(
        remainingHours
      )}小时${formatNumber(remainingMinutes)}分钟${formatNumber(
        remainingSeconds
      )}秒`;

      return formattedDuration;
    },
  },
};
</script>

<style scoped>
.parkingFee {
  width: 15%;
  height: 510px;
  margin-left: 15px;
  background: url(../../assets/bg3.png) 100% no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.parkingFee .title {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.amount_received {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  color: #fff;
}

.fixed-tab-bar {
  display: flex;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  color: #fff;
  font-size: 16px;
}

/* .fixed-tab-bar::-webkit-scrollbar {
  width: 4px;
} */

.active-tab {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.mainInfo {
  width: 100%;
  padding: 0px 10px;
  padding-top: 5px;
  color: #fff;
}
.mainInfo .entranceInfo {
  /* background-color: #777584; */
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 5px;
}
.mainInfo .carInfo {
  /* background-color: #777584; */
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 5px;
}
.mainInfo .feeBasedInfo {
  /* background-color: #777584; */
  padding: 8px;
  border-radius: 5px;
}
.mainInfo .infoTitle {
  font-weight: 700;
}
.infoItem {
  padding: 8px 0;
  margin-left: 20px;
}
.infoItem span {
  margin-right: 10px;
}

.box-chu {
}
</style>
