<template>
  <div class="box8 flex-col">
    <div class="mod6 flex-col">
      <div class="box9 flex-col">
        <span class="word95">入场小图</span>
        <div class="box10 flex-col" />
        <el-image fit="cover" max-scale="10" class="pic2" :preview-src-list="[globalConfig.inOutImageType ? carImage.inSmallImage : carImage.inImage || require('../../assets/image/BG@2x.png')]" :z-index="9999" :src="globalConfig.inOutImageType ? carImage.inSmallImage : carImage.inImage || require('../../assets/image/BG@2x.png')">
          <template #error>
            <div style=" background-color: #f5f7fa17;width: 100%; margin-left: 0%;height: 200px;margin-top: 0;text-align: center;line-height: 200px;color: #abaeb5;">暂无入场小图</div>
          </template>
        </el-image>
      </div>
    </div>
    <div class="mod7 flex-col">
      <div class="layer23 flex-col">
        <span class="info40">出场小图</span>
        <div class="mod8 flex-col" />
        <el-image fit="cover"  max-scale="10"  class="img1" :preview-src-list="[globalConfig.inOutImageType ? carImage.outSmallImage : carImage.outImage || require('../../assets/image/BG@2x.png')]" :z-index="9999" :src="globalConfig.inOutImageType ? carImage.outSmallImage : carImage.outImage || require('../../assets/image/BG@2x.png')">
          <template #error>
            <div class="image-slot">暂无出场小图</div>
          </template>
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["carImage", "globalConfig"]),
  }
};
</script>
<style scoped>
.image-slot {
  /* background-color: #f5f7fa; */
  background-color: #f5f7fa17;
  width: 100%;
  margin-left: 0%;
  height: 200px;
  margin-top: 0;
  text-align: center;
  line-height: 200px;
  color: #abaeb5;
}
</style>